import { Link } from "react-router-dom";
import { RiSparkling2Fill } from "react-icons/ri";

const NotFound = ({ type = "loading", message }) => {
    const FloatingSparkles = () => (
        <div className="absolute inset-0 z-0 pointer-events-none">
            <RiSparkling2Fill className={`absolute text-yellow-500 text-6xl top-1/5 animate-float pointer-events-none`} />
            <RiSparkling2Fill className={`absolute text-yellow-500 text-2xl top-1/4 left-16 animate-float-reverse pointer-events-none`} />
            <RiSparkling2Fill className={`absolute text-yellow-500 text-4xl top-30 right-10 animate-float-reverse pointer-events-none`} />
            <RiSparkling2Fill className={`absolute text-yellow-500 text-4xl bottom-1/3 animate-float-reverse pointer-events-none`} />
            <RiSparkling2Fill className={`absolute text-yellow-500 text-3xl bottom-36 right-1 animate-float pointer-events-none`} />
            <RiSparkling2Fill className={`absolute text-yellow-500 text-6xl bottom-20 right-20 animate-float pointer-events-none`} />
        </div>
    );

    return (
        <div className="bg-gradient-to-b pt-32 sm:pt-40 md:pt-32 from-blue-900 to-slate-950 min-h-screen text-white">
            <div className="relative max-w-3xl mx-auto px-4">
                <FloatingSparkles />
                <div className="flex flex-col relative z-10 justify-center items-center h-auto text-center pb-36">
                    <div className="w-64 h-64 sm:w-64 sm:h-64 md:w-80 md:h-80 flex-shrink-0 rounded-xl mb-6 sm:mb-8 bg-center bg-cover">
                        <img src={`https://starcafe.me/images/notfound.webp`} alt="Page Not Found" className="w-full h-full object-cover" />
                    </div>
                    <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold px-4">
                        Wut da hell is on the URL?
                    </h1>
                    <p className="mt-3 sm:mt-4 text-sm sm:text-base">
                        404 Not Found - The page you're looking for doesn't exist.
                    </p>
                    <p>If you think something is wrong, please email me at <a href="mailto:starcafe.me@gmail.com" className="text-yellow-300 hover:text-yellow-500">starcafe.me@gmail.com</a>.
                    </p>
                    <Link
                        to="/"
                        className="mt-6 px-4 py-2 bg-blue-600 hover:bg-yellow-500 text-white rounded transition-all duration-300"
                    >
                        Go Back Home
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
