import React, { useState } from "react";
import { useAuth } from "../context/AuthContext"; // Assuming you have an AuthContext
import { useAddComment } from "../api/queries";
import { useNavigate} from "react-router-dom";




const CommentSection = ({ comments, chapter, user, login, formatDate }) => {


    const [comment, setComment] = useState("");
    const [hasComment, setHasComment] = useState(false);
   
    const chapterURL = window.location.href;
    const addCommentMutation  = useAddComment();
    const navigate = useNavigate();


    const handleSubmit = (e) => {
        e.preventDefault();
        if (!comment.trim()) return;
        if (user.comment_chapterURLs?.includes(chapterURL)) {
            setHasComment(user.comment_chapterURLs?.includes(chapterURL));
            return;
        }
        

        const newComment = {
            chapterURL: chapterURL,
            comment_content: comment,
            date: new Date().toISOString(), 
            userName: user.userName, 
        };

        const nID = chapter.novel_acronym;
        const cID = chapter.chapter_num;


        addCommentMutation.mutate({
            novelID: nID,
            chapterID: cID,
            comment: newComment,
            userUID: user.uid,

        }, 
            {
                
                onSuccess: (data) => {
                   
                    setHasComment(true);
                    if (data.message === "Comment added successfully") {
                        setComment(""); // ✅ Clear input on success
                    } else {
                        alert("You can only comment once per chapter. Contact me (Button on Footer) if you need to delete your comment.");
                    }
                },
                onError: (error) => {
                    console.error("Failed to add comment:", error);
                    alert("Something went wrong. Please try again.");
                },
            });
    };


     
    return (
        <div className=" shadow-lg p-4 rounded-lg mt-8">
            {/* Add Comment Section (Disabled if not logged in) */}
            <div className="p-6 shadow-lg p-4 rounded-lg relative max-w-3xl mx-auto flex flex-col justify-center bg-gradient-to-b from-slate-950 to-slate-900 shadow-lg">
                {user ? (
                     !hasComment ? <form onSubmit={handleSubmit} className="mb-4 flex flex-col gap-4">
                        <textarea
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder="Write a comment... "
                            className="w-full p-2 bg-gray-800 text-white rounded-lg border border-gray-700 focus:outline-none focus:border-yellow-400"
                            rows="3"
                        />
                        <button
                            type="submit"
                            disabled={!comment.trim() || addCommentMutation.isLoading}
                            className={`self-start px-4 py-2 font-bold rounded ${!comment.trim() || addCommentMutation.isLoading
                                    ? "bg-gray-500 cursor-not-allowed"
                                    : "bg-blue-700 hover:bg-yellow-800 text-white"
                                }`}
                        >
                            {addCommentMutation.isLoading ? "Posting..." : "Comment"}
                        </button>
                    </form> :
                        <div className="py-5 flex items-center justify-center text-slate-300 text-center place-item-center">
                            You can only comment once per chapter. If you want to delete your comment, send a message through the Contact Me form. (It will take 2 hours before your comment can be seen.)
                        </div>                      
                ) : (
                    <div className="flex flex-col justify-center items-center mb-4 text-gray-400">
                        <p className="text-center">You must be logged in to comment.</p>
                        <button
                            onClick={() => navigate(`/profile`)}
                            className="mt-2 px-4 py-2 bg-green-600 hover:bg-green-700 text-white font-bold rounded"
                        >
                            Log in to Comment
                        </button>
                    </div>
                )}


                {/* See Comments Button with Count */}
                {/*<button*/}
                {/*    onClick={() => setShowComments(!showComments)}*/}
                {/*    className="px-4 py-2 bg-blue-700 hover:bg-blue-800 text-white font-bold rounded"*/}
                {/*>*/}
                {/*    {showComments ? "Hide Comments" : `See Comments`}*/}
                {/*    */}{/*{showComments ? "Hide Comments" : `See Comments (${chapter.num_of_comments})`}*/}
                {/*</button>*/}

                {/* Comments List (Hidden by Default) */}
                {/*{showComments && (*/}


                <div className="mt-4 space-y-3 flex flex-col items-center">
                    {chapter?.num_of_comments > 0 && comments?.length > 0 ? (
                        <>
                            <h3 className="text-lg font-bold text-yellow-400">{chapter.num_of_comments} Comments</h3>
                            {[...comments]
                                .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date (newest first)
                                .map((c, index) => (
                                    <div key={c.id || index} className="p-3 shadow-lg rounded-lg w-full">
                                        <div className="flex items-baseline">
                                            <p className="font-bold text-yellow-400 pr-2">{c.userName}</p>
                                            <p className="text-sm font-light text-slate-300">{formatDate(c.date)}</p>
                                        </div>
                                        <p className="text-white">{c.comment_content}</p>
                                    </div>
                                ))}
                        </>
                    ) : comments === undefined ? (
                        <p className="text-gray-400">Loading comments...</p>
                    ) : (
                        <p className="text-slate-200 font-bold py-12">No comments yet. Be the first to comment!</p>
                    )}
                </div>





                {/*)}*/}
            </div>
            
        </div>
    );
};

export default CommentSection;
