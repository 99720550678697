import React, { useEffect, useState } from "react";
import { RiSparkling2Fill, RiEdit2Fill } from "react-icons/ri";
import { useAuth } from "../context/AuthContext"; 
import { useUpdateUsername } from "../api/queries";
import StatusMessage from "../components/StatusMessage";

const FloatingSparkles = () => (
    <>
        <RiSparkling2Fill className="absolute z-0 text-yellow-400 text-6xl top-[50px] animate-float pointer-events-none" />
        <RiSparkling2Fill className="absolute z-0 text-yellow-400 text-2xl top-[200px] left-16 animate-float pointer-events-none" />
        <RiSparkling2Fill className="absolute z-0 text-yellow-400 text-4xl top-32 right-10 animate-float-reverse pointer-events-none" />
        <RiSparkling2Fill className="absolute z-0 text-yellow-400 text-4xl bottom-20 animate-float-reverse pointer-events-none" />
        <RiSparkling2Fill className="absolute z-0 text-yellow-400 text-3xl bottom-28 right-1 animate-float pointer-events-none" />
        <RiSparkling2Fill className="absolute z-0 text-yellow-400 text-6xl bottom-5 right-20 animate-float pointer-events-none" />
    </>
);

const ProfilePage = () => {
    const { user, isAdmin, login, logout, setUser } = useAuth(); // 🔹 Use global auth state
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [newUsername, setNewUsername] = useState("");
    const updateUsernameMutation = useUpdateUsername();




    useEffect(() => {
        const metaTag = document.createElement("meta");
        metaTag.name = "robots";
        metaTag.content = "noindex, nofollow";
        document.head.appendChild(metaTag);

        return () => {
            document.head.removeChild(metaTag);
        };
    }, []);

    const handleUpdateUsername = async (e) => {
        e.preventDefault();


        // Check if new username is not empty
        if (!newUsername.trim()) {
            alert("Username cannot be empty.");
            return;
        }

        if (newUsername.length > 25) {
            alert("Username must be less than 25 characters.");
            return;
        }

        try {
            await updateUsernameMutation.mutateAsync({
                userUID: user.uid,
                newUsername: newUsername,
            });

            setNewUsername("");
            window.location.reload();
        } catch (error) {
            console.error("Mutation error:", error);
        }
    };


    return (
        <div className="bg-gradient-to-b pt-20 pb-72 from-blue-900 to-slate-950 min-h-screen text-white">
            <div className="max-w-5xl pt-20 mx-auto px-6 relative">
                <FloatingSparkles />

                {!user ? (
                    <div className="flex pt-20 justify-center items-center">
                        <button
                            onClick={login} // 🔹 Use login function from AuthContext
                            className="bg-blue-800 hover:bg-blue-900 text-yellow-500 font-bold py-2 px-4 rounded"
                        >
                            Sign in with Google
                        </button>
                    </div>
                ) : (
                    <div className="z-10 max-w-3xl mx-auto mt-10 pb-20">
                            <div className="flex flex-col md:flex-row justify-between items-center  h-full">
                                <div className="flex flex-col items-center  mb-10">
                                    <h2 className="text-2xl font-bold pr-2 text-yellow-500">Welcome back (/=w=)/~ </h2>
                                    <h2 className="text-2xl font-bold ">{user.userName}</h2>
                                </div>
                                <button
                                    onClick={() => setShowLogoutModal(true)}
                                    className="mb-10 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-6 md:mt-0 md:self-auto ">
                                    Sign Out
                                </button>
                            </div>
 
                    
                            {user?.userName === user?.displayName &&  (
                                <div className=" max-w-xl mx-auto bg-blue-900 text-white p-6 rounded-lg shadow-lg">                                    
                                    <form onSubmit={handleUpdateUsername}>
                                        <div className="flex flex-col justify-center items-center">
                                            <h3 className="text-lg font-bold">Set Up Username</h3>
                                            <p title="Note!" className="text-sm ">
                                                You can only change your username once.
                                            </p>
                                            <p title="Note!" className="text-sm text-slate-400 mb-6 font-light">
                                                Maximum of 25 characters.
                                            </p>
                                            <input
                                                type="text"
                                                value={newUsername}
                                                onChange={(e) => setNewUsername(e.target.value)}
                                                placeholder="UserName"
                                                required
                                                className="p-2 border rounded text-black w-64 md:w-96 items-center"
                                            />
                                        </div>
                                        
                                        <div className="flex justify-end mt-4 ">
                                            <button
                                                type="button"
                                                onClick={() => setNewUsername("")}
                                                className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400 mr-2"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                disabled={updateUsernameMutation.isLoading}
                                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-800"
                                            >
                                                {updateUsernameMutation.isLoading ? "Updating..." : "Change Username"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            )}
                    </div>
                )}

                {/* Logout Confirmation Modal */}
                {showLogoutModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white text-black p-6 rounded-lg shadow-lg">
                            <h3 className="text-lg font-bold">Are you sure you want to log out?</h3>
                            <div className="flex justify-end gap-4 mt-4">
                                <button
                                    onClick={() => setShowLogoutModal(false)}
                                    className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => {
                                        logout();
                                        setShowLogoutModal(false);
                                    }}
                                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                                >
                                    Yes, Log Out
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfilePage;
