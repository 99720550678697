import React, { useState, useEffect } from "react";
import Layout from './components/Layout';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { localStoragePersister } from './localStoragePersister';
import StatusMessage from "./components/StatusMessage";
import { AuthProvider } from "./context/AuthContext";

// Create Query Client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 15, // 15 minutes
            cacheTime: 1000 * 60 * 60 * 24, // 24 hours
            refetchOnWindowFocus: false,
        },
    },
});

function App() {
    const [isCacheRestored, setIsCacheRestored] = useState(false);

    useEffect(() => {
        try {
            persistQueryClient({
                queryClient,
                persister: localStoragePersister,
            });
        } catch (err) {
            console.error("Cache restore failed:", err);
        } finally {
            setIsCacheRestored(true); // Allow rendering after cache attempt
        }
    }, []);

    if (!isCacheRestored) {
        return <StatusMessage type="loading" message="Loading..." />; // Prevent rendering until cache is restored
    }

    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider> 
                <Layout />
             </AuthProvider> 
        </QueryClientProvider>
    );
}

export default App;
