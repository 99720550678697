import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import NovelCard from '../components/NovelCard';
import FilterPanel from '../components/FilterPanel';
import SortPanel from '../components/SortPanel';
import { RiSparkling2Fill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const FloatingSparkles = () => (
    <>
        {/* Sparkles - these are absolutely positioned within the header */}
        <RiSparkling2Fill className="absolute text-yellow-400 text-6xl top-[50px] animate-float pointer-events-none" />
        <RiSparkling2Fill className="absolute text-yellow-400 text-2xl top-[200px] left-16 animate-float-reverse pointer-events-none" />
        <RiSparkling2Fill className="absolute text-yellow-400 text-4xl top-32 right-10 animate-float-reverse pointer-events-none" />
        <RiSparkling2Fill className="absolute text-yellow-400 text-4xl bottom-20 animate-float-reverse pointer-events-none" />
        <RiSparkling2Fill className="absolute text-yellow-400 text-3xl bottom-28 right-1 animate-float pointer-events-none" />
        <RiSparkling2Fill className="absolute text-yellow-400 text-6xl bottom-5 right-20 animate-float pointer-events-none" />
    </>
);

const NovelsPage = ({ novels}) => {
 
    
    const [filters, setFilters] = useState({
        pairing: 'All',
        status: 'All',
        tags: [],
    });

    const [sortOptions, setSortOptions] = useState({
        order: 'desc',
        type: 'Date Created',
    });


    const filteredNovels = novels.filter(novel => {
        if (filters.pairing !== 'All' && novel.pairing !== filters.pairing) return false;
        if (filters.status !== 'All' && novel.status !== filters.status) return false;
        if (filters.tags.length > 0 && !filters.tags.every(tag => novel.tags.includes(tag))) return false;
        return true;
    });


    const sortedNovels = [...filteredNovels].sort((a, b) => {
        const { order, type } = sortOptions;
        let comparison = 0;

        if (type === 'Date Created') {
            const dateA = a.date.seconds ? new Date(a.date.seconds * 1000) : new Date(a.date);
            const dateB = b.date.seconds ? new Date(b.date.seconds * 1000) : new Date(b.date);
            comparison = dateA - dateB;
        } else if (type === 'Alphabetically') {
            comparison = a.title.localeCompare(b.title);
        } else if (type === 'Likes') {
            comparison = a.total_likes - b.total_likes;
        }

        return order === 'asc' ? comparison : -comparison;
    });

    const ongoingNovels = novels.filter(novel => novel.status === "Ongoing");


    return (
        <div className="bg-gradient-to-b pt-20 from-blue-900 to-slate-950 min-h-screen text-white">
            <div className="max-w-5xl mx-auto px-6 relative pb-28">

                <FloatingSparkles />

                <header className="flex flex-col items-center justify-center text-center">
                    <div className="max-w-4xl pt-20 mx-auto bg-transparent text-white p-6 pb-10 flex flex-col md:flex-row gap-x-28 gap-y-10 items-center justify-center">
                        <div className="flex flex-col items-center justify-center">
                            <div className="w-40 h-40  mb-4">
                                <img loading="lazy" src={`https://starcafe.me/images/novels.webp`} alt="Pending Novel" className="w-64 h-auto" />
                            </div>
                            <h2 className="font-amatica text-5xl font-bold mb-8">Novels</h2>
                        </div>

                        <div className="text-justify flex flex-col items-center justify-center flex-1 pb-16">
                            <h3 className="font-amatica text-4xl font-bold">Schedules</h3>
                            {ongoingNovels.map((novel) => (
                                <div key={novel.acronym} className="p-4 ">
                                    <Link                                        
                                        to={`/novels/${novel.title.toLowerCase().replace(/ /g, "-").replace(/\?/g, "") }`} 
                                        className="block flex flex-col items-center hover:text-yellow-500"
                                    >
                                        <p className="" > {novel.title}</p>
                                        <p className="font-light">- {novel.update_schedule}</p>   
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </header>

                {/* Filter and Sort Row */}
                <div className="flex flex-col md:flex-row gap-4">
                    {/* Filter (2/3 on desktop, full width on mobile) */}
                    <div className="w-full md:w-2/3">
                        <FilterPanel filters={filters} setFilters={setFilters} />
                    </div>

                    {/* Sort (1/3 on desktop, full width on mobile) */}
                    <div className="w-full md:w-1/3">
                        <SortPanel sortOptions={sortOptions} setSortOptions={setSortOptions} />
                    </div>
                </div>

                {/* Novels Grid */}
                <div className="mt-6 pb-10 flex flex-wrap gap-4 justify-center">
                    {sortedNovels.length > 0 ? (
                        sortedNovels.map((novel) => (
                            <div key={novel.acronym} className="mt-6 flex gap-y-10 gap-x-4">
                                <Link                                
                                    to={`/novels/${(novel.title.toLowerCase().replace(/ /g, "-"))}`} 
                                className="block"
                            >
                                    <NovelCard
                                        title={novel.title}
                                        status={novel.status}
                                        pairing={novel.pairing}
                                        novel_cover={novel.novel_cover}
                                    />
                                </Link>
                            </div>
                        ))
                    ) : (
                        <div className="w-full text-center py-10 pt-28">
                            <p className="text-lg font-semibold text-gray-400">
                                Oops! No novels match your filters. Try adjusting them~
                            </p>
                        </div>
                    )}
                </div>



            </div>
        </div>
    );
};

export default NovelsPage;
