import React from 'react';
import { motion } from 'framer-motion';

const PageTransition = ({ children }) => {
    return (
        <div className="relative">
            {/* Dark overlay */}
            <motion.div
                initial={{ opacity: 1 }}
                animate={{ opacity: 0 }}
                exit={{ opacity: 1 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                className="fixed top-16 mt-2 left-0 w-full h-full bg-slate-950 opcaity-50 z-50 pointer-events-none"
            />
            {/* Page content */}
            {children}
        </div>
    );
};

export default PageTransition;
