import { RiSparkling2Fill } from "react-icons/ri";

const StatusMessage = ({ type = "loading", message }) => {
    const defaultMessages = {
        loading: "Loading...",
        error: "Something went wrong... Maybe you are at the wrong page???",
    };

    const textColor = type === "error" ? "text-red-500" : "text-yellow-500";
    const animation = type === "loading" ? "animate-pulse" : "";

    // Dynamic image based on status type
    const imageUrl =
        type === "error"
            ? "https://starcafe.me/images/error.webp" // Error image
            : "https://starcafe.me/images/pending.webp"; // Loading image


    const FloatingSparkles = () => (
        <>
            <RiSparkling2Fill className={`absolute ${textColor} text-6xl top-[50px] animate-float pointer-events-none`} />
            <RiSparkling2Fill className={`absolute ${textColor} text-2xl top-[200px] left-16 animate-float-reverse pointer-events-none`} />
            <RiSparkling2Fill className={`absolute ${textColor} text-4xl top-16 right-10 animate-float-reverse pointer-events-none`} />
            <RiSparkling2Fill className={`absolute ${textColor} text-4xl bottom-20 animate-float-reverse pointer-events-none`} />
            <RiSparkling2Fill className={`absolute ${textColor} text-3xl bottom-28 right-1 animate-float pointer-events-none`} />
            <RiSparkling2Fill className={`absolute ${textColor} text-6xl bottom-5 right-20 animate-float pointer-events-none`} />
        </>
    );

    return (
        <div className="bg-gradient-to-b pt-36 from-blue-900 to-slate-950 min-h-screen text-white">
            <div className="relative max-w-3xl mx-auto">
                <FloatingSparkles />
                <div className={`flex flex-col justify-center items-center h-96 font-amatica ${textColor}`}>
                    {/* Dynamic Image */}
                    <div
                        className="w-80 h-80 md:w-96 md:h-96 flex-shrink-0 rounded-xl mb-8 bg-center bg-cover"
                        style={{ backgroundImage: `url(${imageUrl})` }}
                    ></div>

                    {/* Message */}
                    <p className={`text-4xl font-bold text-center ${animation}`}>
                        {message || defaultMessages[type]}
                    </p>
                    <p className="text-center">
                        If you think something is wrong, please email me at starcafe.me@gmail.com
                    </p>
                </div>
            </div>
        </div>
    );
};

export default StatusMessage;
