import React from "react";
import { RiSparkling2Fill } from 'react-icons/ri';
import {  Link } from "react-router-dom";

const FloatingSparkles = () => {
    return (
        <>
            {/* Sparkles - these are absolutely positioned within the header */}
            <RiSparkling2Fill className="absolute text-yellow-400 text-6xl top-[50px] animate-float pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-2xl top-[200px] left-16 animate-float-reverse pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-4xl top-32 right-10 animate-float-reverse pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-4xl bottom-20 animate-float-reverse pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-3xl bottom-28 right-1 animate-float pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-6xl bottom-5 right-20 animate-float pointer-events-none" />
        </>
    );
};

const PrivacyPolicyPage = () => {
    return (
        <div className="bg-gradient-to-b pt-20 from-blue-900 to-slate-950 min-h-screen text-white">
            <div className="max-w-5xl mx-auto px-6 relative">
                <FloatingSparkles />
                <header className="text-center relative py-10">
                    <h1 className="mt-10 font-amatica text-5xl font-semibold">Privacy Policy</h1>
                </header>

                <section className="max-w-3xl mx-auto mt-8 space-y-6 pb-36">
                    <p>
                        Welcome to <strong>StarCafe</strong>. Your privacy is important to us. This Privacy Policy explains what data we collect, how we use it, and your rights regarding your information.
                    </p>

                    <h2 className="text-2xl font-semibold mt-6">1. Information We Collect</h2>
                    <p>We collect limited information to improve our services. This may include:</p>
                    <ul className="list-disc list-inside space-y-2">
                        <li><strong>Google Sign-In Data:</strong> If you sign in using Google, we receive your basic profile information, including your Google ID, display name, and email address.</li>
                        <li><strong>Contact Form Data:</strong> When users submit a message through our contact form, they may provide their name and email address (optional). The message itself is required.</li>
                        <li><strong>Usage Data:</strong> We may collect non-personal data such as IP addresses, browser type, and pages visited to analyze site performance.</li>
                        <li><strong>Cookies & Local Storage:</strong> We may use cookies and local storage to enhance site functionality and improve user experience.</li>
                    </ul>

                    <p className="p-1"></p>

                    <h2 className="text-2xl font-semibold mt-6">2. How We Use Your Information</h2>
                    <p>We use the collected data for:</p>
                    <ul className="list-disc list-inside space-y-2">
                        <li>Improving website performance and user experience.</li>
                        <li>Responding to messages submitted through the contact form.</li>
                        <li>Analyzing site traffic and engagement trends.</li>
                    </ul>

                    <p className="p-1"></p>

                    <h2 className="text-2xl font-semibold mt-6">3. Third-Party Services</h2>
                    <p>We may use third-party services that collect data independently:</p>
                    <ul className="list-disc list-inside space-y-2">
                        <li><strong>Google AdSense:</strong> Displays ads and may use cookies and tracking technologies for personalized advertising. You can opt out via <a href="https://adssettings.google.com/" target="_blank" rel="noopener noreferrer" className="text-blue-300 underline">Google Ad Settings</a>.</li>
                        <li><strong>Google Analytics:</strong> Tracks website usage. Google may collect data such as IP addresses and browsing behavior. See <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-300 underline">Google’s Privacy Policy</a> for more details.</li>
                    </ul>

                    <p className="p-1"></p>

                    <h2 className="text-2xl font-semibold mt-6">4. Your Rights & Choices</h2>
                    <p>You can:</p>
                    <ul className="list-disc list-inside space-y-2">
                        <li>Disable cookies in your browser settings.</li>
                        <li>Opt out of Google Ad personalization <a href="https://adssettings.google.com/" target="_blank" rel="noopener noreferrer" className="text-blue-300 underline">here</a>.</li>
                        <li>Request data deletion for any personal data you have provided via our contact form.</li>
                    </ul>

                    <p className="p-1"></p>

                    <h2 className="text-2xl font-semibold mt-6">5. Data Retention</h2>
                    <ul className="list-disc list-inside space-y-2">
                        <li>We do not store contact form messages permanently. Messages are deleted periodically once they are no longer needed.</li>
                        <li>Usage data is analyzed in an aggregated, anonymous manner and does not identify individual users.</li>
                    </ul>

                    <p className="p-1"></p>

                    <h2 className="text-2xl font-semibold mt-6">6. Changes to This Policy</h2>
                    <p>
                        We may update this Privacy Policy from time to time. Please check this page periodically for any changes.
                    </p>

                    <p className="p-1"></p>

                    <h2 className="text-2xl font-semibold mt-6">7. Contact Us</h2>
                    <p>
                        If you have any questions about this Privacy Policy, you can contact us through <Link to="/about/#contactMe" className="text-blue-300 underline">this form</Link>.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;
