import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";
import { RiSparkling2Fill } from 'react-icons/ri';
import { useSupportContent } from "../api/queries";
import StatusMessage from "../components/StatusMessage";


const FloatingSparkles = () => {
    return (
        <>
            {/* Sparkles - these are absolutely positioned within the header */}
            <RiSparkling2Fill className="absolute text-yellow-400 text-6xl top-[50px] animate-float pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-2xl top-[200px] left-16  animate-float-reverse pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-4xl top-32 right-10 animate-float-reverse pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-4xl bottom-20 animate-float-reverse pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-3xl bottom-28 right-1 animate-float pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-6xl bottom-5 right-20 animate-float pointer-events-none" />
        </>
    );
}


const SupportPage = () => {
    const navigate = useNavigate();
    const { data: support, error, isLoading } = useSupportContent();

    if (isLoading) return <StatusMessage type="loading" message="Fetching chapter details..." />;
    if (error) return <StatusMessage type="error" message="Chapter not found" />;



    return (
        <div className="bg-gradient-to-b pt-20 from-blue-900 to-slate-950 min-h-screen text-white">
            <div className="max-w-5xl mx-auto px-6 relative"> {/* Limits width */}
                <FloatingSparkles />

                {/* Header */}
                <header className="flex flex-col items-center justify-center text-center">
                    {/* Container for the announcement content */}
                    <div className="max-w-4xl pt-20 mx-auto bg-transparent  text-white p-6 pb-10 flex flex-col md:flex-row gap-x-28 gap-y-10 items-center justify-center">
                        <div className="flex flex-col items-center justify-center">
                            <div className="w-40 h-40  mb-4">
                                <img src={`https://starcafe.me/images/support.webp`} alt="Pending Novel" className="w-64 h-auto" />
                            </div>
                            <h2 className="font-amatica text-5xl font-bold mb-8">Support Me</h2>
                            <a
                                href="https://ko-fi.com/melalah"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-white w-40 h-8 p-1 text-red-500 mb-2 rounded-lg font-bold flex items-center justify-center cursor-pointer hover:bg-amber-700 hover:text-black"
                            >
                                Buy me a Coffee
                            </a>
                            <a
                                href="https://paypal.me/melalah"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-yellow-600 text-blue-800 w-40 h-8 p-1 rounded-lg font-bold flex items-center justify-center cursor-pointer hover:bg-blue-700 hover:text-yellow-500"
                            >
                                Paypal
                            </a>
                        </div>

                        <div className="flex flex-col  items-center justify-center flex-1 ">
                            <h3 className="font-amatica text-3xl font-bold">Details</h3>
                            <p className=" leading-relaxed font-semibold pt-4 text-center">
                                > $3 donation
                            </p>
                            <p className="text-sm leading-relaxed">
                                Extra chapter Update
                            </p>
                            <p className="leading-relaxed font-semibold pt-4 text-center">
                                > Reaching $15 donation goal
                            </p>
                            <p className="text-sm leading-relaxed">
                                Extra chapter update on all ongoing novel as a thank you bonus.
                            </p>
                        </div>
                    </div>
                </header>

                {/* Support Ads Section */}
                <section className="pt-4 pb-36  flex flex-col items-center justify-center text-center">
                    <h2 className="font-amatica text-5xl font-bold mb-2">Support Through Ads!</h2>
                    <h3 className="text-lg font-semibold">Your visits help keep the site running and support more translations! If you use an ad blocker, please consider whitelisting this site to support future updates. Thank you!</h3>
                </section>


                {/* Advance Chapters Section */}
                <section className="pt-4 pb-20 flex flex-col items-center justify-center text-center">
                    <div>
                        <h2 className="font-amatica text-5xl font-bold mb-2">Advance Chapters!</h2>
                        <h3 className="max-w-3xl">
                            With Ko-Fi Membership, subscribed supporters can read chapters in advance~
                        </h3>
                    </div>

                    {/* Responsive Container */}
                    <div className="flex flex-wrap justify-center gap-6 pt-8">
                        {/* Little Star */}
                        <a href="https://ko-fi.com/melalah/tiers" target="_blank" rel="noopener noreferrer">
                            <div
                                className="relative w-60 h-80 rounded-lg overflow-hidden"
                                style={{
                                    backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/starcafe-167bb.firebasestorage.app/o/LittleStar.webp?alt=media&token=6f08a830-110d-43ba-8a11-c9562c938cc3)`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                }}
                            >
                                <div className="absolute inset-0 flex flex-col items-center justify-center text-center rounded-lg bg-gradient-to-b from-transparent to-black hover:bg-gradient-to-t hover:from-blue-950 hover:from-5% hover:to-transparent">
                                    <h2 className="font-amatica text-5xl font-bold text-yellow-400">Little Star</h2>
                                    <h2 className="text-2xl font-bold text-yellow-500">$3</h2>
                                    <h2 className="text-slate-200 px-4">Advance 2 Chapters on Ko-Fi exclusive Content!</h2>
                                </div>
                            </div>
                        </a>

                        {/* Super Nova */}
                        <a href="https://ko-fi.com/melalah/tiers" target="_blank" rel="noopener noreferrer">
                            <div
                                className="relative w-60 h-80 rounded-lg overflow-hidden"
                                style={{
                                    backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/starcafe-167bb.firebasestorage.app/o/SuperNova.webp?alt=media&token=eb62d6c8-3ac5-424b-a626-301448a7edb8)`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                }}
                            >
                                <div className="absolute inset-0 flex flex-col items-center justify-center text-center rounded-lg bg-gradient-to-b from-transparent to-black hover:bg-gradient-to-t hover:from-blue-950 hover:from-20% hover:to-transparent">
                                    <h2 className="font-amatica text-5xl font-bold text-slate-200">Super Nova</h2>
                                    <h2 className="text-2xl font-bold text-slate-200">$5</h2>
                                    <h2 className="text-slate-200 px-4 ">Advance 4 Chapters on Ko-Fi exclusive Content!</h2>
                                </div>
                            </div>
                        </a>

                        {/* Nebula Rebirth */}
                        <a href="https://ko-fi.com/melalah/tiers" target="_blank" rel="noopener noreferrer">
                            <div
                                className="relative w-60 h-80  rounded-lg overflow-hidden"
                                style={{
                                    backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/starcafe-167bb.firebasestorage.app/o/NebulaRebirth.webp?alt=media&token=8e35ccc5-02ac-4e3f-8f1e-105ebac108ce)`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                }}
                            >
                                <div className="  absolute inset-0 flex flex-col items-center justify-center text-center rounded-lg bg-gradient-to-b from-transparent to-black hover:bg-gradient-to-t hover:from-blue-950 hover:from-5% hover:to-transparent">
                                    <h2 className="font-amatica text-5xl font-bold text-black">Nebula Rebirth</h2>
                                    <h2 className="text-2xl font-bold text-black">$10</h2>
                                    <h2 className="text-slate-200 px-4 pb-6">Advance 8 Chapters on Ko-Fi exclusive Content!</h2>
                                </div>
                            </div>
                        </a>
                    </div>
                </section>



                {/* Support Appreciation Section */}
                <section className="py-16 pb-40 text-white text-center flex flex-col items-center justify-center">
                    <h2 className="font-amatica text-5xl font-bold mb-10">THANK YOU SO MUCH!</h2>

                    <div className="max-w-4xl w-full flex flex-col items-center space-y-2 font-semibold">
                        {support?.support_list?.slice().reverse().map((htmlString, index) => (
                            <div key={index} dangerouslySetInnerHTML={{ __html: htmlString }} />
                        ))}

                    </div>
                </section>


                
            </div>
        </div>
    );
};

export default SupportPage;
