import React, { useState } from "react";
import { RiSparkling2Fill } from "react-icons/ri";
import { useCreateMessage } from "../api/queries"; // Import the mutation
import ScrollToHash from "../components/ScrollToHash";

const FloatingSparkles = () => {
    return (
        <>
            <RiSparkling2Fill className="absolute text-yellow-400 text-6xl top-[50px] animate-float pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-2xl top-[200px] left-16  animate-float-reverse pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-4xl top-32 right-10 animate-float-reverse pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-4xl bottom-20 animate-float-reverse pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-3xl bottom-28 right-1 animate-float pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-6xl bottom-5 right-20 animate-float pointer-events-none" />
        </>
    );
};

const AboutPage = () => {
    const { mutate: sendMessage, isLoading, error } = useCreateMessage();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.message.trim()) return; // Prevent empty messages

        const messageData = {
            name: formData.name,
            email: formData.email,
            message_content: formData.message,
            is_message: true,
            date: new Date().toISOString(), // Ensure correct timestamp format
        };

        sendMessage(messageData, {
            onSuccess: () => {
                alert("Message sent successfully!");
                setFormData({ name: "", email: "", message: "" });
            },
            onError: (err) => {
                alert(`Failed to send message: ${err.message}`);
            },
        });
    };

    return (
        <div className="bg-gradient-to-b pt-20 from-blue-900 to-slate-950 min-h-screen text-white">
            <div className="max-w-5xl mx-auto px-6 relative text-slate-200">
                <FloatingSparkles />

                <header className="flex mb-10 flex-col items-center justify-center text-center">
                    <div className="py-20 max-w-2xl mx-auto bg-transparent text-white p-6 pb-10 flex flex-col md:flex-row gap-x-28 gap-y-10 items-center justify-center">
                        <div className="flex flex-col items-center justify-center">
                            <div className="w-40 h-40  mb-4">
                                <img src={`https://starcafe.me/images/hi.webp`} alt="Pending About" className="w-64 h-auto" />
                            </div>
                            <h2 className="font-amatica text-5xl font-bold mb-1">About</h2>
                            <div className="font-light">Me, yours truly, Melalah</div>
                        </div>
                        <div className="flex flex-col items-center justify-center flex-1">
                            <h3 className="font-amatica text-3xl font-bold">Hello, and welcome!</h3>
                            <div className="text-sm font-semibold leading-relaxed pt-4 text-center">
                                I’m a freelance digital artist and amateur translator.
                                This platform is my creative space to share my fan translations with the world.
                            </div>
                        </div>
                    </div>
                </header>

                <div className="max-w-5xl mx-auto bg-transparent text-white p-6 flex flex-col md:flex-row gap-x-28 gap-y-10 items-center justify-center">
                    <div className="flex-1 flex flex-col items-center">
                        <h2 className="font-amatica text-5xl font-bold mb-4">Translations</h2>
                        <div className="w-40 h-40  mb-4">
                            <img src={`https://starcafe.me/images/translation.webp`} alt="Pending Translation" className="w-64 h-auto" />
                        </div>
                        <div className="text-justify text-sm leading-relaxed pb-3">
                            English is not my native language, but I think I’m pretty good at it XD! Additionally, I’m still learning Chinese, so I rely on tools like Google Translate, DeepL, and ChatGPT to assist me in creating fan translations.
                        </div>
                        <div className="text-justify text-sm leading-relaxed pb-3">
                            My focus is translating *not so popular* Chinese novels into something that is readable and enjoyable for readers.
                            I strive for clarity and engagement in my translations, but they may not be perfectly accurate due to reliance on machine translation.
                        </div>
                        <div className="text-justify text-sm leading-relaxed pb-3">
                            I do not own the novels I translate. My work is purely fan-based, and I encourage you to support the original authors by visiting the links (click on the raw title) provided on each novel’s page.
                        </div>
                    </div>

                    <div className="flex-1 flex flex-col items-center pb-6">
                        <h2 className="font-amatica text-5xl font-bold mb-4">Artworks</h2>
                        <div className="text-justify text-sm leading-relaxed py-4">
                            In addition to translations, I accept digital art commissions. While my work may not rival that of the most experienced artists, I strive to provide affordable, quick, and satisfying artwork tailored to your needs.
                            As reference, all images featured on this website are my original creations, except for novel covers or unless explicitly stated otherwise.
                        </div>
                        <div className="w-40 h-40  mb-4">
                            <img src={`https://starcafe.me/images/artwork.webp`} alt="Pending Artwork" className="w-64 h-auto" />
                        </div>
                        <a
                            href="https://ko-fi.com/melalah/commissions"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-yellow-600 w-40 h-8 p-1  mb-2 rounded-lg font-bold flex items-center justify-center cursor-pointer hover:bg-amber-700 hover:text-black"
                        >
                            Commission
                        </a>
                    </div>
                </div>

                {/* Contact Me Section */}
                <div className="flex justify-center items-center pb-10">
                    <section className="py-28 w-full max-w-3xl text-center">
                        <ScrollToHash targetHash="#contactMe">
                            <h2 className="font-amatica text-5xl font-bold mb-4">Contact Me</h2>
                        </ScrollToHash>
                        <form onSubmit={handleSubmit} className="bg-transparent p-6 rounded-lg shadow-md space-y-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Name"
                                    className="p-2 border rounded w-full text-black"
                                />
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Email"
                                    className="p-2 border rounded w-full text-black"
                                />
                            </div>
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                placeholder="Message*"
                                className="p-2 border rounded w-full h-40 text-black"
                            />
                            <div className="flex justify-center">
                                <button type="submit" disabled={isLoading} className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-yellow-500 hover:text-black">
                                    {isLoading ? "Sending..." : "Send"}
                                </button>
                            </div>
                            {error && <p className="text-red-500 mt-2">Error sending message.</p>}
                        </form>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default AboutPage;
