import React from 'react';

const NovelCard = ({ title, status, pairing, novel_cover }) => {
    return (
        <div className="relative w-52 h-72 border-1 border-indigo-900 rounded-lg overflow-hidden hover:text-xl">

            {/* Background image with gradient overlay */}
            <div className="text-black text-xl hover:text-white w-full h-full bg-cover bg-center relative  " style={{ backgroundImage: `url(${novel_cover})` }}>
                {/* Gradient overlay */}
                <div className="absolute  hover:font-bold hover:text-2xl inset-0 bg-gradient-to-b hover:bg-gradient-to-t  from-transparent hover:from-black from-60% hover:from-10% to-white  hover:to-transparent ">

                    {/* Status Badge - Top Left */}
                    <div className={` absolute top-2 left-2 text-black text-sm font-bold px-2 py-1 rounded ${status === 'Complete' ? 'bg-orange-400' : 'bg-red-500'}`}>
                        {status}
                    </div>

                    {/* Pairing Label - Top Right */}
                    <div className="absolute top-2 right-2 text-lg font-medium">
                        {pairing}
                    </div>

                    {/* Novel Title - Bottom Left */}
                    <div className="absolute font-amatica bottom-2 left-2 font-semibold pr-2">
                        {title}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NovelCard;
