import { createContext, useContext, useEffect, useState } from "react";
import { auth, provider, db } from "../firebase";
import {
    getAuth,
    onAuthStateChanged,
    signInWithPopup,
    signOut
} from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                await handleUserLogin(currentUser);
            } else {
                setUser(null);
                setIsAdmin(false);
            }
        });

        return () => unsubscribe();
    }, []);


    const handleUserLogin = async (currentUser) => {
        const userRef = doc(db, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
            // 🔥 Create new user in Firestore
            const newUser = {
                userUID: currentUser.uid,
                email: currentUser.email,
                is_admin: false,
                userName: currentUser.displayName,
                comment_chapterURLs: [],
            };
            await setDoc(userRef, newUser);
            setUser({ ...currentUser, ...newUser });
        } else {
            // 🔥 Load existing user data
            setUser({ ...currentUser, ...userSnap.data() });
        }

        //try {
        //    const loginData = {
        //        userUID: currentUser.uid,
        //        email: currentUser.email,
        //        displayName: currentUser.displayName 
        //    };
        //    const userData = await loginMutation.mutateAsync(loginData); // Call backend
        //    setUser({
        //        ...currentUser, 
        //        is_admin: userData.is_admin || false,  
        //        comment_chapterURLs: userData.comment_chapterURLs || [], 
        //        userName: userData.userName,
        //        last_username_change: userData.last_username_change || null
        //    });
        //} catch (error) {
        //    console.error("Login error:", error);
        //}
    };

    // 🔹 Login function (Google Sign-In)
    const login = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            await handleUserLogin(result.user);
        } catch (error) {
            console.error("Login error:", error);
        }
    };

    // 🔹 Logout function
    const logout = async () => {
        await signOut(auth);
        setUser(null);
        setIsAdmin(false);
        //window.location.reload();

    };

    return (
        <AuthContext.Provider value={{ user, isAdmin, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Hook to use authentication
export const useAuth = () => useContext(AuthContext);
