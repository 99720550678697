import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { RiSparkling2Fill } from "react-icons/ri";
import { BiSolidMegaphone } from "react-icons/bi";
import {  FaHeart } from "react-icons/fa";
import { useChapter, useAnnouncements, useGetComments } from "../api/queries";
import StatusMessage from "../components/StatusMessage";
import CommentSection from "../components/CommentSection";
import NotFound from "../pages/NotFound"; 
import { useAuth } from "../context/AuthContext"; 
import { db } from "../firebase";
import { doc, updateDoc, increment } from "firebase/firestore";

const FloatingSparkles = () => (
    <div className="absolute inset-0 z-0 pointer-events-none"> {/* Ensure it's behind content */}
        <BiSolidMegaphone className="absolute text-6xl text-slate-900 top-[60px] left-2 animate-float" />
        <BiSolidMegaphone className="absolute text-yellow-500 text-6xl top-[50px] animate-float" />
        <RiSparkling2Fill className="absolute text-yellow-400 text-2xl top-[200px] left-16 animate-float-reverse" />
        <RiSparkling2Fill className="absolute text-yellow-400 text-6xl top-32 right-10 animate-float-reverse" />
        <RiSparkling2Fill className="absolute text-yellow-400 text-4xl bottom-20 animate-float-reverse" />
        <RiSparkling2Fill className="absolute text-yellow-400 text-3xl bottom-28 right-1 animate-float" />
        <RiSparkling2Fill className="absolute text-yellow-400 text-6xl bottom-5 right-20 animate-float" />
    </div>
);



const Chapter = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname.includes("%3f") || location.pathname.includes("%3F")) {
            const correctedUrl = location.pathname.replace("%3f", "").replace("%3F", "") + location.search;
            navigate(correctedUrl, { replace: true });
        }
    }, [location, navigate]);

    const { novelTitle, chapterUrlTitle } = useParams();
    const [normalizedAcronym, chapterNum] = chapterUrlTitle.split("-ch");

    const { data: chapter, error: chapterError, isLoading: chapterLoading } = useChapter(normalizedAcronym, chapterNum);
    const { data: announcements, error: announcementError, isLoading: announcementLoading } = useAnnouncements();
    const { data: comments, error: commentError, isLoading: commentLoading } = useGetComments(normalizedAcronym, chapterNum);

    const { user } = useAuth(); // Get user and login function
    const [liked, setLiked] = useState(false); // Track if user liked
    const mainAnnouncement = announcements?.find(a => a.is_main === true);

    // Load the saved font size from localStorage or default to 16
    const [fontSize, setFontSize] = useState(() => {
        return parseInt(localStorage.getItem("fontSize"), 10) || 16;
    });

    // Update localStorage whenever fontSize changes
    useEffect(() => {
        localStorage.setItem("fontSize", fontSize);
    }, [fontSize]);

    const changeFontSize = (delta) => {
        setFontSize((prev) => Math.min(Math.max(prev + delta, 12), 24));
    };

    useEffect(() => {
    const likedChapters = JSON.parse(localStorage.getItem("likedChapters")) || [];
        setLiked(likedChapters.includes(chapterUrlTitle));
    }, [chapterUrlTitle]);


    if (chapterLoading || announcementLoading || commentLoading) return <StatusMessage type="loading" message="Fetching chapter details..." />;
    if (chapterError || announcementError || commentError) return <StatusMessage type="error" message="Chapter not found T^T" />;


    if (chapter.content === "Naughty user.") return <NotFound />;

    const goToChapter = (num) => {
        navigate(`/novels/${encodeURIComponent(novelTitle.toLowerCase().replace(/ /g, "-"))}/${normalizedAcronym}-ch${num}`);
    };


    const formatDate = (timestamp) => {
        if (!timestamp) return "Invalid Date";

        const date = new Date(timestamp); // Parse ISO string

        return date.toLocaleString('en-US', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true, // 12-hour format with AM/PM
        });
    };



    // Handle like button click
    const handleLike = async () => {
        
        if (!user) {
            alert("Please log in to like this chapter.");
            navigate('/profile');
            return;
        }

        const likedChapters = JSON.parse(localStorage.getItem("likedChapters")) || [];

        if (likedChapters.includes(chapterUrlTitle)) {
            return;
        }
        

        try {            
            const novelRef = doc(db, "novels", normalizedAcronym);

            await updateDoc(novelRef, {
                total_likes: increment(1), // Increment likes in Firestore
            });

            setLiked(true);
            const updatedLikedChapters = [...likedChapters, chapterUrlTitle];
            localStorage.setItem("likedChapters", JSON.stringify(updatedLikedChapters));


        } catch (error) {
            console.error("Error liking chapter:", error);
        }
    };

    return (
        <div className="bg-gradient-to-b pt-20 from-blue-900 to-slate-950 min-h-screen text-white">
            <div className="relative max-w-5xl mx-auto px-6 pb-28">
                <FloatingSparkles />

                {/* Announcement Banner */}
                <div className="flex items-center justify-center mb-10">
                    {mainAnnouncement && (
                        <div
                            className=" flex items-center justify-center mt-8 shadow-lg shadow-slate-800 rounded-lg hover:bg-yellow-500 bg-blue-800 hover:text-black text-white text-center h-20 w-3/4 max-w-5xl p-3 cursor-pointer"
                            onClick={() => navigate("/#announcements")}
                        >
                            <h2 className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold ">{mainAnnouncement.headline}</h2>
                        </div>
                    )}
                </div>

                {/* Chapter Header */}
                <div className="my-4 text-center relative z-10">
                    <div onClick={() => navigate(`/novels/${encodeURIComponent(novelTitle.toLowerCase().replace(/ /g, "-"))}`)} className="font-amatica text-3xl font-bold hover:underline">{chapter.novel_title}</div>
                    <h2 className="text-lg mt-2">Chapter {chapter.chapter_num}:</h2>
                    <h3 className="text-2xl italic">{chapter.chapter_title}</h3>
                    <div className=" text-sm text-gray-300">
                        {formatDate(chapter.date)}
                    </div>
                </div>

                {chapter.content === "Too early user." ?
                    <div className=" mt-20 text-yellow-500 font-bold text-2xl max-w-3xl mx-auto flex flex-col justify-center text-center items-center">
                        <div >
                            The chapter is here, but it's not the scheduled time yet!
                        </div>
                        <div className="flex flex-col text-center">
                            Please come back on or after
                            <span className="text-red-500"> {formatDate(chapter.date)} </span>
                            <div className="text-sm text-gray-400">
                                (Because I'm not sure what time it'll be at your place XD)
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className="max-w-3xl mx-auto flex flex-col ">
                            {/* Top Navigation Buttons */}
                            <div className="flex items-center gap-2 mt-6 justify-center items-center ">
                                {!chapter.is_first && (
                                    <button
                                        onClick={() => goToChapter(Math.max(1, chapter.chapter_num - 1))}
                                        className="p-2 bg-blue-800 shadow-md rounded hover:bg-yellow-500 hover:text-black shadow-blue-950"
                                    >
                                        ←
                                    </button>
                                )}

                                <button
                                    onClick={() => navigate(`/novels/${encodeURIComponent(novelTitle.toLowerCase().replace(/ /g, "-"))}#chapters`)}
                                    className="p-2 bg-blue-800 shadow-md rounded hover:bg-yellow-500 hover:text-black shadow-blue-950"
                                >
                                    ToC
                                </button>

                                {!chapter.is_latest && (
                                    <button
                                        onClick={() => goToChapter(chapter.chapter_num + 1)}
                                        className="p-2 bg-blue-800 shadow-md rounded hover:bg-yellow-500 hover:text-black shadow-blue-950"
                                    >
                                        →
                                    </button>
                                )}

                            </div>

                            {/* Translator Note Top */}
                            {chapter.tn_top && (
                                //className = " mt-10 text-sm italic text-yellow-300 "
                                < div
                                    className="mt-6 max-w-3xl w-full leading-relaxed text-sm italic text-yellow-300">
                                    <div dangerouslySetInnerHTML={{ __html: chapter.tn_top }} />
                                </div>
                            )}

                            <div className="flex flex-col justify-center items-center">

                                <div className="w-32 h-[1px] bg-white mt-10"></div>

                                {/* Chapter settings */}
                                <div className="flex m-2 gap-4">
                                    <button onClick={() => changeFontSize(-2)} title="Decrease Font Size" className="rounded hover:font-bold hover:text-yellow-500">A-</button>
                                    <button onClick={() => changeFontSize(2)} title="Increase Font Size" className="rounded hover:font-bold hover:text-yellow-500">A+</button>
                                    {/* Like Button */}
                                    <button
                                        onClick={handleLike}
                                        className={`py-2 rounded`}
                                        title="Like This Chapter"
                                    >
                                        <FaHeart className={liked ? "text-yellow-500 " : "text-blue-400 hover:text-white" } />
                                    </button>

                                </div>

                                <div className="w-32 h-[1px] bg-white mb-10 flex justify-center">
                                    <p className="mt-4">*</p>
                                </div>
                            </div>


                            {/* Chapter Content */}
                            <div
                                className="mt-6 max-w-3xl w-full leading-relaxed text-slate-200"
                                style={{ fontSize: `${fontSize}px`, lineHeight: "1.8" }}
                                dangerouslySetInnerHTML={{ __html: chapter.content.replace(/<p>/g, '<p style="margin-bottom: 1em;">') }}
                            />

                            <div className="flex flex-col justify-center items-center mt-10">
                                <p className="">*</p>
                                <div className="w-32 h-[1px] bg-white my-2 flex justify-center"></div>
                                    <button
                                        onClick={handleLike}
                                        className={`py-2 rounded`}
                                        title="Like This Chapter"
                                    >
                                        <FaHeart className={liked ? "text-yellow-500 " : "text-blue-400 hover:text-white"} />
                                    </button>
                                <div className="w-32 h-[1px] bg-white mt-2"></div>
                            </div>




                            {/* Translator Note Bottom */}
                            {chapter.tn_bot && (
                                <div className="mt-4 text-sm italic text-yellow-300">
                                    <div dangerouslySetInnerHTML={{ __html: chapter.tn_bot }} />
                                </div>
                            )}

                            {/* Bottom Navigation */}
                            <div className="flex justify-center items-center items-center gap-2 mt-10">
                                {!chapter.is_first && (
                                    <button
                                        onClick={() => goToChapter(Math.max(1, chapter.chapter_num - 1))}
                                        className="p-2 bg-blue-800 shadow-md rounded hover:bg-yellow-500 hover:text-black shadow-blue-950"
                                    >
                                        ←
                                    </button>
                                )}
                                <button
                                    onClick={() => navigate(`/novels/${encodeURIComponent(novelTitle.toLowerCase().replace(/ /g, "-"))}#chapters`)}
                                    className="p-2 bg-blue-800 shadow-md rounded hover:bg-yellow-500 hover:text-black shadow-blue-950"
                                >
                                    ToC
                                </button>

                                {!chapter.is_latest && (
                                    <button
                                        onClick={() => goToChapter(chapter.chapter_num + 1)}
                                        className="p-2 bg-blue-800 shadow-md rounded hover:bg-yellow-500 hover:text-black shadow-blue-950"
                                    >
                                        →
                                    </button>
                                )}
                            </div>
                        </div>

                        {/* Buy me a coffee */}
                        <div className="flex flex-col max-w-xl mx-auto mt-8 text-center justify-center">
                            <p className="text-yellow-300 text-sm">
                                ✨ Enjoying my translations? ✨
                            </p>
                            <p className="text-sm mt-1">
                                Your support and appreciation means the world to me!
                                <br />
                                Consider buying me a coffee by clicking below — it keeps me motivated and helps me release extra chapters for everyone!
                            </p>
                            <div onClick={() => navigate("/support")} className="text-sm mt-1 underline pb-4">
                                For more info, please visit the support page!
                            </div>
                            <a href="https://ko-fi.com/melalah" target="_blank" rel="noopener noreferrer" className="w-48 mx-auto mt-3 inline-block bg-black border-2 border-white text-yellow-500 px-4 py-2 rounded-xl font-bold hover:bg-yellow-500 hover:text-black hover:border-yellow-500">
                                ☕ Buy me a Coffee
                            </a>
                        </div>
                        <CommentSection comments={comments} chapter={chapter} user={user} formatDate={formatDate } />
                    </div>
                }
            </div>
        </div>
    );
};

export default Chapter;
