import { useRssFeed } from "../api/queries";
import StatusMessage from "../components/StatusMessage";

const RssPage = () => {
    const { data: rssData, isLoading, isError } = useRssFeed();

    if (isLoading) return <StatusMessage type="loading" message="Loading..." />;
    if (isError) return <StatusMessage type="error" message="Something went wrong T^T" />;

    // Ensure rssData is an array
    //if (!Array.isArray(rssData)) {
    //    return <div className="pt-40 bg-yellow-500">Invalid RSS feed format.</div>;
    //}

    return (
        <div className="bg-gradient-to-b pt-20 from-blue-900 to-slate-950 min-h-screen ">
            <div className="relative pt-10 max-w-5xl mx-auto px-6 relative pb-28 text-slate-200">
                <h1 className="text-2xl font-bold mb-4 pl-4">RSS Feed</h1>
                <pre className="whitespace-pre-wrap break-words text-sm bg-gray-900 p-4 rounded">
                    {typeof rssData === "string" ? rssData : JSON.stringify(rssData, null, 2)}
                </pre>
            </div>

            {/*<ul className="space-y-4">*/}
            {/*    {rssData.map((item, index) => (*/}
            {/*        <li key={index} className="border p-4 rounded bg-gray-50">*/}
            {/*            <h2 className="text-lg font-semibold">{item.title}</h2>*/}
            {/*            <p>Chapter: {item.chapterNumber || "N/A"}</p>*/}
            {/*            <p className="text-blue-500">Link: <a href={item.link} target="_blank" rel="noopener noreferrer">{item.link}</a></p>*/}
            {/*            <p>Published: {new Date(item.pubDate).toLocaleString()}</p>*/}
            {/*        </li>*/}
            {/*    ))}*/}
            {/*</ul>*/}
        </div>
    );
};

export default RssPage;
