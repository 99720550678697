import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDEyw_oa-BQ0mzei-vPcH41hupcapptVxo",
    authDomain: "starcafe-167bb.firebaseapp.com",
    projectId: "starcafe-167bb",
    storageBucket: "starcafe-167bb.firebasestorage.app",
    messagingSenderId: "676390399989",
    appId: "1:676390399989:web:1cfa70fd2a7f1b95f9fabc",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

export { app, auth, provider, db };
