import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import PageTransition from './PageTransition';
import ScrollToTop from './ScrollToTop';
import Navbar from './Navbar';
import Footer from './Footer';
import Home from '../pages/Home';
import Novels from '../pages/Novels';
import Novel from '../pages/Novel';
import About from '../pages/About';
import Support from '../pages/Support';
import Profile from '../pages/Profile';
import Chapter from '../pages/Chapter';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsAndConditions from '../pages/TermsAndConditions';
import NotFound from "../pages/NotFound"; 
import { useNovels, useAnnouncements } from "../api/queries";
import StatusMessage from "./StatusMessage";
import RssPage from "../pages/RssPage";



const Layout = () => {
    const location = useLocation();

    const { data: announcements, error: announcementError, isLoading: announcementLoading } = useAnnouncements();
    const { data: novels, error: novelError, isLoading: novelLoading } = useNovels();

    // State to track screen width
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Function to wrap pages with PageTransition only if not mobile
    const wrapWithTransition = (Component, props) => {
        //if (isMobile) {
        //    return <Component />;
        //} else {
            return (
                <PageTransition>
                    <Component {...props} />
                </PageTransition>
            );
        //}
    };

    // Page title mapping
    useEffect(() => {
        let newTitle = "StarCafe"; // Default Title
        const hash = location.hash;
        const pathParts = location.pathname.split("/").filter(Boolean); // Remove empty parts

        if (pathParts[0] === "novels") {
            if (pathParts.length === 1) {
                newTitle = "Novels | StarCafe";
            } else if (pathParts.length === 2) {
                // URL Format: /novels/:novelTitle
                const novelTitle = decodeURIComponent(pathParts[1]).replace(/-/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());; // Convert dashes back to spaces
                newTitle = `${novelTitle} | StarCafe`;
            } else if (pathParts.length === 3) {
                // URL Format: /novels/:novelTitle/:chapterUrlTitle
                const novelAcronym = pathParts[2].split("-ch")[0].toUpperCase(); // Extract acronym
                const chapterNumber = pathParts[2].split("-ch")[1]; // Extract chapter number
                newTitle = `Ch${chapterNumber} ${novelAcronym} | StarCafe`;
            }
        } else {
            // Static Pages
            const pageTitles = {
                "/novels": "Novels | StarCafe",
                "/support": "Support | StarCafe",
                "/about": "About | StarCafe",
                "/profile": "Profile | StarCafe",
                "/privacy-policy": "Privacy Policy | StarCafe",
                "/terms-and-conditions": "Terms & Conditions | StarCafe",
            };

            newTitle = pageTitles[location.pathname] || "StarCafe";
        }
        if (hash === "#announcements") {
            newTitle = "Announcements | StarCafe";
        }

        document.title = newTitle;
    }, [location.pathname]);


    // Show loading or error only ONCE in Layout
    if (announcementLoading || novelLoading) return <StatusMessage type="loading" message="Loading..." />;
    if (announcementError || novelError) return <StatusMessage type="error" message="Something went wrong T^T" />;


    return (
        <div className="flex flex-col min-h-auto">
            <Navbar novels={ novels } />
                <ScrollToTop />
                <AnimatePresence mode="wait">
                    <Routes location={location} key={location.pathname}>
                        <Route path="/" element={wrapWithTransition(Home, { novels, announcements })} />
                        <Route path="/novels" element={wrapWithTransition(Novels, {novels})} />
                        <Route path="/novels/:novelTitle" element={wrapWithTransition(Novel, { novels })} />
                        <Route path="/novels/:novelTitle/:chapterUrlTitle" element={<Chapter />} />
                        <Route path="/support" element={wrapWithTransition(Support)} />
                        <Route path="/about" element={wrapWithTransition(About)} />
                        <Route path="/profile" element={wrapWithTransition(Profile)} />
                        <Route path="/privacy-policy" element={wrapWithTransition(PrivacyPolicy)} />
                        <Route path="/terms-and-conditions" element={wrapWithTransition(TermsAndConditions)} />
                        <Route path="/rss" element={<RssPage />} />
                        <Route path="/sitemap.xml" element={null} />
                        <Route path="/*" element={<NotFound />} />
                        <Route path="/not-found" element={<NotFound />} />
                    </Routes>
                </AnimatePresence>
                <Footer />
        </div>
    );

};

export default Layout;
