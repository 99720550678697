import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const ScrollToHash = ({ targetHash, children }) => {
    const location = useLocation();
    const ref = useRef(null);

    useEffect(() => {
        // Only scroll if the hash matches AND we're on the same page
        if (location.hash === targetHash && ref.current && location.pathname === window.location.pathname) {
            ref.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [location, targetHash]);

    return <div ref={ref}>{children}</div>;
};

export default ScrollToHash;
