import { PersistedClient } from "@tanstack/react-query-persist-client";

const STORAGE_KEY = "REACT_QUERY_OFFLINE_CACHE";

// Create a LocalStorage Persister
export const localStoragePersister = {
    persistClient: (client: PersistedClient) => {
        try {
            const data = {
                ...client,
                timestamp: Date.now(), // Store when data was saved
            };
            localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
        } catch (error) {
            console.error("Failed to persist cache:", error);
        }
    },

    restoreClient: () => {
        try {
            const cachedData = localStorage.getItem(STORAGE_KEY);
            if (!cachedData) return undefined;

            const parsedData = JSON.parse(cachedData);

            // Optional: Check cache expiration (e.g., 24h cacheTime)
            const MAX_AGE = 1000 * 60 * 60 * 24; // 24 hours
            if (Date.now() - parsedData.timestamp > MAX_AGE) {
                console.warn("Cache expired, clearing...");
                localStorage.removeItem(STORAGE_KEY);
                return undefined;
            }

            return parsedData;
        } catch (error) {
            console.error("Failed to restore cache:", error);
            return undefined;
        }
    },

    removeClient: () => {
        try {
            localStorage.removeItem(STORAGE_KEY);
        } catch (error) {
            console.error("Failed to remove cache:", error);
        }
    },
};
