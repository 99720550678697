import { db, auth } from "../firebase"; // ✅ Correctly importing db & auth
import {
    collection, doc, getDocs, getDoc, setDoc, addDoc
} from "firebase/firestore"; // ✅ Import Firestore functions from Firebase SDK
// 🔹 Login or Register User (Authentication)
export const loginOrRegisterUser = async (userId, email, displayName) => {
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
        const newUser = {
            userUID: userId,
            email: email,
            is_admin: false,
            userName: displayName,
            last_username_change: new Date().toISOString(),
            comment_chapterURLs: []
        };
        await setDoc(userRef, newUser);
        return newUser;
    }

    return userSnap.data();
};

// 🔹 Fetch Novels
export const getNovels = async () => {
    const novelsRef = collection(db, "novels");
    const novelsSnapshot = await getDocs(novelsRef);
    return novelsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

// 🔹 Fetch Announcements
export const getAnnouncements = async () => {
    const announcementsRef = collection(db, "announcements");
    const announcementsSnapshot = await getDocs(announcementsRef);
    return announcementsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

// 🔹 Fetch Chapter (With Restriction)
export const getChapter = async (novelId, chapterID) => {
    const chapterRef = doc(db, "novels", novelId, "chapters", chapterID);
    const chapterSnap = await getDoc(chapterRef);
    const chapterData = chapterSnap.data();

    if (!chapterData?.is_published) {
        return { ...chapterData, content: "Too early user." };
    }

    return chapterData;
};

 //🔹 Fetch Comments for a Chapter
export const getComments = async (novelId, chapterID) => {
    const commentsRef = collection(db, "novels", novelId, "chapters", chapterID, "comments");
    const commentsSnapshot = await getDocs(commentsRef);
    return commentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

// 🔹 Fetch Support Content
export const getSupportContent = async () => {
    const supportRef = doc(db, "supports", "l37XfLheao588pmUDE7Hsupport");
    const supportSnap = await getDoc(supportRef);

    if (!supportSnap.exists()) {
        throw new Error("Support content not found.");
    }

    return supportSnap.data();
};

// 🔹 Add Message
export const addMessage = async (message) => {
    const messagesRef = collection(db, "messages");
    await addDoc(messagesRef, { ...message, date: new Date().toISOString() });
};
