import axios from "axios";

const API_BASE_URL = "https://api.starcafe.me/api";
//const API_BASE_URL = "https://starcafe-676390399989.us-central1.run.app/api";
//const API_BASE_URL = "http://localhost:8080/api";
//const API_BASE_URL = "https://localhost:5164/api";



export const addComment = async ({ novelID, chapterID, userUID, comment }) => {
    const response = await axios.post(`${API_BASE_URL}/Chapters/${novelID}/${chapterID}/comment?userUID=${userUID}`,
        comment
    );
    return response.data;
};

export const updateUsername = async ({ userUID, newUsername }) => {
    const response = await axios.patch(`${API_BASE_URL}/Users/change-username`, {
        userUID,
        newUsername,
    });
    return response.data;
};

export const getRssFeed = async () => {
    const response = await axios.get(`${API_BASE_URL}/RSS`, {
        headers: { "Accept": "application/xml" }
    });
    return response.data;
};



export const getComments = async (novelID, chapterID) => {
    const response = await axios.get(`${API_BASE_URL}/Chapters/${novelID}/${chapterID}/comments`);
    return response.data;
};


export const getChapter = async (novelId, chapterId) => {
    const response = await axios.get(`${API_BASE_URL}/Chapters/${novelId}/${chapterId}`);
    return response.data;
};

export const getAnnouncements = async () => {
    const response = await axios.get(`${API_BASE_URL}/Announcements`);
    return response.data;
};

export const getNovels = async () => {
    const response = await axios.get(`${API_BASE_URL}/Novels`);
    return response.data;
};



//export const createMessage = async (messageData) => {
//    const response = await axios.post(`${API_BASE_URL}/Messages`, messageData);
//    return response.data;
//};





//// 🔹 Announcements


//export const createAnnouncement = async (announcementData) => {
//    const response = await axios.post(`${API_BASE_URL}/Announcements`, announcementData);
//    return response.data;
//};

// 🔹 Messages
//export const getMessages = async () => {
//    const response = await axios.get(`${API_BASE_URL}/Messages`);
//    return response.data;
//};
//export const getSupportMessages = async () => {
//    const response = await axios.get(`${API_BASE_URL}/Messages/support`);
//    return response.data;
//};



// 🔹 Novels

//export const getNovelById = async (novelId) => {
//    const response = await axios.get(`${API_BASE_URL}/Novels/${novelId}`);
//    return response.data;
//};
//export const createNovel = async (novelData) => {
//    const response = await axios.post(`${API_BASE_URL}/Novels`, novelData);
//    return response.data;
//};


// 🔹 Chapters
//export const createChapter = async (chapterData) => {
//    const response = await axios.post(`${API_BASE_URL}/Chapters`, chapterData);
//    return response.data;
//};
//export const updateChapter = async (novelId, chapterId, chapterData) => {
//    const response = await axios.patch(`${API_BASE_URL}/Chapters/${novelId}/${chapterId}`, chapterData);
//    return response.data;
//};



//export const deleteComment = async (novelID, chapterID, commentID) => {
//    const response = await axios.delete(
//        `${API_BASE_URL}/chapters/${novelID}/${chapterID}/${commentID}`,
//        { withCredentials: true }
//    );
//    return response.data;
//};

//export const fetchSupportContent = async () => {
//    try {
//        const response = await axios.get(`${API_BASE_URL}/support`);
//        console.log("API Response:", response.data); // Debugging log
//        return response.data;
//    } catch (error) {
//        console.error("Error fetching support content:", error);
//        throw error; // Ensure React Query knows there is an error
//    }
//};