import React, { useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';

const SortPanel = ({ sortOptions, setSortOptions }) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleExpanded = () => setIsExpanded(!isExpanded);

    const resetSort = () => {
        setSortOptions({
            order: 'desc',
            type: 'Date Created',
        });
    };

    const handleOrderChange = (order) => {
        setSortOptions(prev => ({ ...prev, order }));
    };

    const handleTypeChange = (type) => {
        setSortOptions(prev => ({ ...prev, type }));
    };

    return (
        <div className="relative bg-[#190b4c] text-white rounded-lg shadow-lg p-4">
            {/* Header Row with Reset and Collapse */}
            <div className="flex justify-between items-center">
                <div>
                    <span className="font-bold pr-4">Sort</span>
                    <button onClick={toggleExpanded}>
                        {isExpanded ? <IoChevronUp /> : <IoChevronDown />}
                    </button>
                </div>
                <div className="flex items-center gap-3">
                    <FaTrash
                        className="cursor-pointer hover:text-yellow-400"
                        onClick={resetSort}
                    />
                </div>
            </div>

            {/* Collapsible Sort Options */}
            {isExpanded && (
                <div className="flex flex-row  justify-center mt-4 space-x-5">
                    {/* Ascend / Descend */}
                    <div>
                        <div className="text-sm text-center pb-2">Order</div>
                        <div className="flex flex-col gap-2">
                            <button
                                className={`px-3 py-1 rounded ${sortOptions.order === 'asc' ? 'bg-yellow-500 text-black' : 'bg-blue-800'}`}
                                onClick={() => handleOrderChange('asc')}
                            >
                                Ascend
                            </button>
                            <button
                                className={`px-3 py-1 rounded ${sortOptions.order === 'desc' ? 'bg-yellow-500 text-black' : 'bg-blue-800'}`}
                                onClick={() => handleOrderChange('desc')}
                            >
                                Descend
                            </button>
                        </div>
                    </div>

                    {/* Sort Type */}
                    <div>
                        <div className="text-sm text-center pb-2">Options</div>
                        <div className="flex flex-col gap-2">
                            {['Date Created', 'Alphabetically', 'Likes'].map(type => (
                                <button
                                    key={type}
                                    className={`px-3 py-1 rounded ${sortOptions.type === type ? 'bg-yellow-500 text-black' : 'bg-blue-800'}`}
                                    onClick={() => handleTypeChange(type)}
                                >
                                    {type}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SortPanel;
