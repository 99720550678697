import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import NovelCard from '../components/NovelCard';
import { RiSparkling2Fill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import ScrollToHash from "../components/ScrollToHash";

const FloatingSparkles = () => {
    return (
        <>
            {/* Sparkles - these are absolutely positioned within the header */}
            <RiSparkling2Fill className="absolute text-yellow-400 text-6xl top-10 left-16 animate-float pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-2xl top-28 left-0 animate-float-reverse pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-4xl top-32 right-40 animate-float-reverse pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-4xl bottom-36 left-40 animate-float-reverse pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-3xl bottom-28 right-1 animate-float pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-6xl bottom-5 right-20 animate-float pointer-events-none" />
        </>
    );
};

const HomePage = ({ novels, announcements }) => {
    const navigate = useNavigate();

    const isCentered = novels.length < 4;

    const formatDate = (timestamp) => {
        if (!timestamp) return "Invalid Date";

        const date = new Date(timestamp); // Parse ISO string

        return date.toLocaleString('en-US', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true, // 12-hour format with AM/PM
        });
    };


    const handleNavigate = (title) => navigate(`/novels/${title.toLowerCase().replace(/ /g, "-")}`);


    return (
            <div className="bg-gradient-to-b from-blue-900 to-slate-950 min-h-screen text-white">
                <div className="bg-gradient-to-b pt-20 from-blue-900 to-slate-950 min-h-screen text-white">
                    <div className="max-w-5xl mx-auto px-6"> {/* Limits width */}

                        {/* Header */}
                        <header className="text-center relative py-10">
                            <FloatingSparkles />
                            <h1 className="mt-28 pt-20 font-amatica text-5xl font-semibold">WELCOME TO</h1>
                            <h2 className="mb-28 pb-20 font-amatica text-8xl font-bold">StarCafe</h2>
                        </header>

                    {/* Announcements Section */}
                    <div>
                        <ScrollToHash targetHash="#announcements" />
                    </div>
                    <section className="py-20 flex flex-col items-center justify-center text-center">
                            <h2 className="font-amatica text-5xl font-bold mb-8">ANNOUNCEMENTS</h2>

                            <Swiper
                                spaceBetween={20}
                                pagination={{ clickable: true }}
                                modules={[Pagination, Autoplay]}
                                autoplay={{ delay: 5000, disableOnInteraction: true }}
                                className="w-full max-w-5xl"
                                loop={false}
                                centeredSlides={announcements.length < 4}  // Center if fewer than 4
                                breakpoints={{
                                    0: { slidesPerView: 1 },
                                    768: { slidesPerView: 1 },
                                    1024: { slidesPerView: 1 }
                                }}
                            >
                            {announcements
                                .slice() // Create a shallow copy to avoid mutating the original array
                                .sort((a, b) => (b.is_main === true) - (a.is_main === true)) // Sort: is_main=true first
                                .map((announcement, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="relative rounded-lg p-6 mb-2 text-left flex flex-col md:flex-row items-center gap-6 shadow-[0_4px_3px_rgba(10,10,50,0.5)]  w-15/16 max-w-full mx-auto h-[600px] md:h-[400px]">
                                            {/* Image placeholder */}
                                            <div className="w-48 h-64 flex-shrink-0 rounded-xl">
                                                <img
                                                    src={announcement.announcement_cover}
                                                    alt="Announcement Cover"
                                                    loading="lazy"
                                                    decoding="async"
                                                    className="w-48 h-64 object-contain rounded-xl"
                                                />
                                            </div>

                                            {/* Announcement Content */}
                                            <div className="z-10 flex-1 flex flex-col justify-start overflow-hidden">
                                                {/* Scrollable title area */}
                                                <div className="min-h-[60px] max-h-[250px] leading-relaxed overflow-y-auto ">
                                                    <h3 className="text-xl font-bold">{announcement.headline}</h3>
                                                    <div className="text-sm text-gray-300">
                                                        {announcement.tags.join(", ")}
                                                    </div>
                                                </div>

                                                {/* Scrollable content area */}
                                                <div className="mt-4 text-sm leading-relaxed overflow-y-auto pr-2 announcement-content max-h-[500px] md:max-h-[250px]">
                                                    <div dangerouslySetInnerHTML={{ __html: announcement.content }} />
                                                </div>
                                            </div>


                                            {/* Sparkles */}
                                            <RiSparkling2Fill className="absolute  z-0 text-yellow-400 text-6xl top-5 left-16 animate-float pointer-events-none" />
                                            <RiSparkling2Fill className="absolute z-0 text-yellow-400 text-2xl top-28 left-0 animate-float-reverse pointer-events-none" />
                                            <RiSparkling2Fill className="absolute z-0 text-yellow-400 text-3xl bottom-28 right-1 animate-float pointer-events-none" />
                                            <RiSparkling2Fill className="absolute  z-0 text-yellow-400 text-6xl bottom-0 right-10 animate-float pointer-events-none" />
                                        </div>
                                    </SwiperSlide>
                                ))}

                            </Swiper>
                        </section>




                        {/* Recommendations Section */}
                        <section className="py-28 ">
                            <h2 className="font-amatica text-5xl font-bold mb-8 text-center">RECOMMENDATIONS</h2>

                            <Swiper
                                spaceBetween={10}
                                pagination={{ clickable: true }}
                                modules={[Pagination, Autoplay]}
                                autoplay={{ delay: 5000, disableOnInteraction: false }}
                                className="w-full"
                                loop={!isCentered}
                                centeredSlides={isCentered}
                                breakpoints={{
                                    0: { slidesPerView: 1 }, // 1 card for extra small screens
                                    480: { slidesPerView: 3 }, // 2 cards at ~mobile landscape
                                    640: { slidesPerView: 3 }, // 2 cards for tablets
                                    1024: { slidesPerView: 4 } // 4 cards for desktops
                                }}
                            >
                                {novels.map((novel, i) => (
                                    <SwiperSlide key={i}>
                                        <div onClick={() => handleNavigate(novel.title)} className="flex justify-center items-center h-full">
                                            <NovelCard
                                                title={novel.title}
                                                status={novel.status}
                                                pairing={novel.pairing}
                                                novel_cover={novel.novel_cover}
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </section>


                        {/* Latest Update Section */}
                        <div className="flex justify-center items-center pb-10">
                            <section className="py-28  w-full max-w-3xl text-center"> {/* Center section & control width */}
                                <h2 className="font-amatica text-5xl font-bold mb-10">Latest Updates</h2>
                                <div className="space-y-2">
                                {novels.map((novel, index) => (
                                        <button
                                            key={index}
                                            onClick={() => navigate(`/novels/${novel.title.toLowerCase().replace(/ /g, "-").replace(/\?/g, "")}/${novel.acronym}-ch${novel.latest_chapter}`)}
                                            className="flex md:flex-row flex-col md:justify-between justify-center items-center text-sm md:text-base cursor-pointer hover:bg-yellow-300 hover:text-black p-2 rounded transition w-full text-left"
                                        >
                                            <span className="md:w-32">Chapter {novel.latest_chapter}</span>
                                            <span className="flex-1 text-center font-bold">{novel.title}</span>
                                            <span className="md:w-32 text-right italic">{formatDate(novel.latest_update)}</span>
                                        </button>
                                    ))}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default HomePage;
