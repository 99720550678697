import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaSearch, FaBars, FaTimes, FaUser } from "react-icons/fa";
import { useNovels } from "../api/queries";
import StatusMessage from "../components/StatusMessage";

const Navbar = ({ novels }) => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredNovels, setFilteredNovels] = useState([]);
    const searchRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                setIsVisible(true);
            } else if (window.scrollY > lastScrollY) {
                setIsVisible(false);
            } else {
                setIsVisible(true);
            }
            setLastScrollY(window.scrollY);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [lastScrollY]);

    useEffect(() => {
        if (!searchQuery.trim()) {
            setFilteredNovels([]);
        } else {
            setFilteredNovels(
                novels?.filter((novel) =>
                    novel.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    novel.acronym.toLowerCase().includes(searchQuery.toLowerCase())
                ) || []
            );
        }
    }, [searchQuery, novels]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setFilteredNovels([]);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div className={`fixed top-0 w-full z-50 bg-white shadow-md transition-transform duration-300 ${isVisible ? "translate-y-0" : "-translate-y-full"}`}>
            <nav className="bg-slate-950 text-white p-4 shadow-lg">
                <div className="max-w-6xl mx-auto flex items-center justify-between">
                    <div className="flex items-center space-x-8">
                        <Link to="/" className="font-amatica text-3xl pb-1 font-bold text-yellow-400 tracking-widest">
                            Starcafe
                        </Link>
                        {/* Desktop Navigation */}
                        <ul className="hidden md:flex space-x-6">
                            {["/", "/novels", "/support", "/about"].map((path, i) => (
                                <li key={i} className={`cursor-pointer ${location.pathname === path ? "text-yellow-400 font-semibold" : "hover:text-gray-400"}`}>
                                    <Link to={path}>
                                        {path === "/" ? "Home" : path.replace("/", "").charAt(0).toUpperCase() + path.slice(2)}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="flex flex-row">
                        {/* ✅ Search Bar - Visible on Desktop */}
                        <div className="hidden md:flex items-center relative " ref={searchRef}>
                            <input
                                type="text"
                                placeholder="Search novels..."
                                className="px-4 py-2 rounded-full text-black w-[200px] sm:w-[250px] focus:outline-none"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <FaSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600 cursor-pointer" />

                            {/* ✅ Search Results Dropdown */}
                            {filteredNovels.length > 0 && (
                                <ul className="absolute top-full left-0 w-full bg-white shadow-lg rounded-md mt-2 max-h-60 overflow-y-auto">
                                    {filteredNovels.map((novel, index) => (
                                        <li key={index} className="p-2 hover:bg-gray-200 cursor-pointer">
                                            <Link to={`/novels/${novel.title.toLowerCase().replace(/ /g, "-").replace(/\?/g, "") }`} className="text-black" onClick={() => setSearchQuery("")}>
                                                {novel.title} ({novel.acronym})
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        {/* User Profile */}
                        <Link to="/profile" className="hidden md:flex items-center pl-4">
                            <FaUser className="text-2xl cursor-pointer hover:text-gray-400" />
                        </Link>
                    </div>

                    

                    {/* ✅ Mobile Menu Button */}
                    <button onClick={() => setIsOpen(!isOpen)} className="md:hidden text-2xl focus:outline-none text-blue-400">
                        {isOpen ? <FaTimes /> : <FaBars />}
                    </button>
                </div>

                {/* ✅ Mobile Menu (Slide Down) */}
                {isOpen && (
                    <div className="flex flex-col items-center md:hidden mt-4 bg-slate-900 text-white py-4 px-6 space-y-4">
                        <ul className="space-y-4">
                            {["/", "/novels", "/support", "/about"].map((path, i) => (
                                <li key={i}>
                                    <Link
                                        to={path}
                                        className={`block py-2 ${location.pathname === path ? "text-yellow-400 font-semibold" : "hover:text-gray-400"}`}
                                        onClick={() => setIsOpen(false)} // Close menu when clicking a link
                                    >
                                        {path === "/" ? "Home" : path.replace("/", "").charAt(0).toUpperCase() + path.slice(2)}
                                    </Link>
                                </li>
                            ))}
                        </ul>

                        {/* ✅ Mobile Search Bar */}
                        <div className="relative" ref={searchRef}>
                            <input
                                type="text"
                                placeholder="Search novels..."
                                className="w-full px-4 py-2 rounded-full text-black focus:outline-none"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <FaSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600 cursor-pointer" />

                            {filteredNovels.length > 0 && (
                                <ul className="absolute top-full left-0 w-full bg-white shadow-lg rounded-md mt-2 max-h-60 overflow-y-auto text-black">
                                    {filteredNovels.map((novel, index) => (
                                        <li key={index} className="p-2 hover:bg-gray-200 cursor-pointer">
                                            <Link to={`/novels/${novel.title.toLowerCase().replace(/ /g, "-").replace(/\?/g, "")}`} className="text-black" onClick={() => { setSearchQuery(""); setIsOpen(false)}}>
                                                {novel.title} ({novel.acronym})
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        {/* ✅ Mobile Profile Icon */}
                        <Link to="/profile" className="block text-center mt-4" onClick={() => setIsOpen(false)}>
                            <FaUser className="text-2xl mx-auto hover:text-gray-400" />
                        </Link>
                    </div>
                )}
            </nav>
        </div>
    );
};

export default Navbar;
