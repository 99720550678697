import React, { useState } from 'react';
import { useParams,  Link } from "react-router-dom";
import { RiSparkling2Fill } from 'react-icons/ri';
import ScrollToHash from "../components/ScrollToHash";
import StatusMessage from "../components/StatusMessage";
import { FaHeart } from "react-icons/fa";
import NotFound from "../pages/NotFound"; 

const FloatingSparkles = () => (
    <>
        {/* Sparkles - these are absolutely positioned within the header */}
        <RiSparkling2Fill className="absolute text-yellow-400 text-6xl top-[50px] animate-float pointer-events-none" />
        <RiSparkling2Fill className="absolute text-yellow-400 text-2xl top-[200px] left-16 animate-float-reverse pointer-events-none" />
        <RiSparkling2Fill className="absolute text-yellow-400 text-4xl top-32 right-10 animate-float-reverse pointer-events-none" />
        <RiSparkling2Fill className="absolute text-yellow-400 text-4xl bottom-20 animate-float-reverse pointer-events-none" />
        <RiSparkling2Fill className="absolute text-yellow-400 text-3xl bottom-28 right-1 animate-float pointer-events-none" />
        <RiSparkling2Fill className="absolute text-yellow-400 text-6xl bottom-5 right-20 animate-float pointer-events-none" />
    </>
);

const NovelPage = ({ novels }) => {
    
    const { novelTitle } = useParams();
    const decodedTitle = decodeURIComponent(novelTitle)
        .replace(/-/g, " ") // Replace hyphens with spaces
        .toLowerCase() // Convert everything to lowercase
        .replace(/\b\w/g, (c) => c.toUpperCase()); // Capitalize each word



    const title = decodedTitle === "When An Alien Is Bound To A World Saving System"
        ? "When An Alien Is Bound To A World-Saving System"
        : decodedTitle === "Did You Steal My Mage Tower"
            ? "Did You Steal My Mage Tower?"
            : decodedTitle;


    const novel = novels.find(novel => novel.title === title);
    const [showHelp, setShowHelp] = useState(false);

    if (!novel) return <NotFound />;

    const startChapter = parseInt(novel.start_chapter) || 1;
    const totalChapters = parseInt(novel.latest_chapter - novel.start_chapter + 1) || 0;


    return (
        <div className="bg-gradient-to-b pt-20 from-blue-900 to-slate-950 min-h-screen ">
            <div className="relative pt-10 max-w-5xl mx-auto px-6 relative pb-28 text-slate-200">

                <FloatingSparkles />

                {/* Flex container to hold cover and info */}
                <div className="max-w-3xl flex flex-col mb-10 mx-auto md:flex-row mt-4 gap-6 justify-center items-center">

                    {/* Novel Cover */}
                    <div className="min-w-40 flex flex-col justify-center items-center">
                        <div className="flex-shrink-0">
                            <img
                                src={novel.novel_cover}
                                alt={`${novel.title} Cover`}
                                loading="lazy"
                                className="w-40 h-60 object-cover bg-gray-700 rounded-lg"
                            />
                        </div>
                        <div className="flex items-center relative pr-2 pt-1">
                            <FaHeart className="text-yellow-500 " />
                            <p className="pl-2">{novel.total_likes}</p>
                        </div>
                        <a href={novel.nu_link} target="_blank" rel="noreferrer" className="underline">NU Link</a>
                    </div>

                    {/* Novel Info */}
                    <div>
                        <h1 className="text-3xl font-bold mb-6">{novel.title}</h1>
                        <div className="space-y-2 text-md">
                                <p><strong>Author:</strong> <a href={novel.author_link} target="_blank" rel="noreferrer" className="underline">{novel.author}</a></p>
                                <p><strong>Raw:</strong> <a href={novel.raw_link} target="_blank" rel="noreferrer" className="underline">Raw Link</a></p>
                                <p><strong>Number of Chapters:</strong> {novel.num_of_chapters}</p>
                                <p><strong>Pairing:</strong> {novel.pairing}</p>
                                <p><strong>Tags:</strong> {novel.tags.join(", ")}</p>
                                <p><strong>Status:</strong> {novel.status}</p>
                                {novel.status !== "Complete" ?
                                    <p><strong>Schedule:</strong> {novel.update_schedule}</p>   :  <p></p>
                                }                    
                        </div>
                    </div>
                </div>

                <div className=" mx-auto max-w-4xl mb-20">
                    <h2 className="mt-6 text-xl font-bold item-center">Description</h2>
                    <div
                        className="mt-2 leading-relaxed  space-y-2"
                        dangerouslySetInnerHTML={{ __html: novel.description }}
                    ></div>

                    <ScrollToHash targetHash="#chapters">
                        <h2 id="chapters" className="flex justify-center mb-6 mt-6 text-xl font-bold">Chapters List</h2>
                    </ScrollToHash>

                    <div className="mt-2 grid grid-cols-5 md:grid-cols-10 gap-2 text-center text-sm">
                    {Array.from({ length: totalChapters }, (_, i) => (
                        <Link
                            to={`/novels/${novelTitle.toLowerCase().replace(/ /g, "-")}/${novel.acronym}-ch${startChapter + i}`}
                            key={i}
                            className="p-2 bg-slate-800 text-white rounded hover:bg-yellow-400 hover:text-black"
                        >
                            {startChapter + i}
                        </Link>
                    ))}
                    </div>

                </div>

            </div>
        </div>
    );

};

export default NovelPage;
