import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
    getRssFeed, updateUsername, addComment, getAnnouncements,
    getComments, getNovels, getChapter,
    //getSupportMessages, createMessage, getMessages, getNovelById, fetchSupportContent, deleteComment,
} from "./apiService";
import { getSupportContent, addMessage  } from "./firebaseService";


//Direct to firebase
export const useAnnouncements = () => useQuery({
    queryKey: ["announcements"],
    queryFn: getAnnouncements,
});

export const useNovels = () => useQuery({
    queryKey: ["novels"],
    queryFn: getNovels,
});

export const useChapter = (novelId, chapterId) => useQuery({
    queryKey: ["chapter", novelId, chapterId],
    queryFn: () => getChapter(novelId, chapterId),
});

export const useGetComments = (novelID, chapterID) => {
    return useQuery({
        queryKey: ["comments", novelID, chapterID], // Unique key for caching
        queryFn: () => getComments(novelID, chapterID), // Call Firestore function
    });
};

export const useSupportContent = () => {
    return useQuery({
        queryKey: ["support"],
        queryFn: getSupportContent,
    });
};

export const useCreateMessage = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: addMessage,
        onSuccess: () => {
            queryClient.invalidateQueries(["messages"]);
        },
    });
};



//API
export const useUpdateUsername = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateUsername,
        onSuccess: (_, { userUID }) => {
            queryClient.invalidateQueries(["users", userUID]);
        },
    });
};

export const useAddComment = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: addComment,
        onSuccess: (_, { novelID, chapterID }) => {
            queryClient.invalidateQueries(["comments", novelID, chapterID]); // ✅ Refetch comments
        },
    });
};


export const useRssFeed = () => useQuery({
    queryKey: ["rssFeed"],
    queryFn: getRssFeed,
});


//export const useInitiate = () => {
//    return useQuery({
//        queryKey: ["initiateData"],
//        queryFn: fetchInitiateData,
//    });
//};



// ✅ Fetch Messages
//export const useMessages = () => useQuery({
//    queryKey: ["messages"],
//    queryFn: getMessages,
//});



// ✅ Fetch Supports
//export const useSupportMessages = () => useQuery({
//    queryKey: ["supportMessages"],
//    queryFn: getSupportContent,
//});



// ✅ Fetch a Novel
//export const useNovel = (novelId) => useQuery({
//    queryKey: ["novel", novelId],
//    queryFn: () => getNovelById(novelId),
//});


//export const useDeleteComment = () => {
//    const queryClient = useQueryClient();

//    return useMutation({
//        mutationFn: deleteComment,
//        onSuccess: (_, { novelID, chapterID }) => {
//            queryClient.invalidateQueries(["comments", novelID, chapterID]);
//        },
//    });
//};


