import React, { useState } from 'react';
import { FaTrash, FaQuestionCircle } from 'react-icons/fa';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';

const FilterPanel = ({ filters, setFilters }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [showHelp, setShowHelp] = useState(false);

    const toggleExpanded = () => setIsExpanded(!isExpanded);

    const resetFilters = () => {
        setFilters({
            pairing: 'All',
            status: 'All',
            tags: [],
        });
    };

    const handlePairingSelect = (pairing) => {
        setFilters(prev => ({ ...prev, pairing }));
    };

    const handleStatusSelect = (status) => {
        setFilters(prev => ({ ...prev, status }));
    };

    const toggleTag = (tag) => {
        setFilters(prev => ({
            ...prev,
            tags: prev.tags.includes(tag)
                ? prev.tags.filter(t => t !== tag)
                : [...prev.tags, tag],
        }));
    };

    const pairingOptions = ['All', 'BG', 'BL'];
    const statusOptions = ['All', 'Complete', 'Ongoing', 'Dropped'];
    const tagOptions = ['Apocalypse', 'World-Hopping', 'System', 'Magic'];

    return (
        <div className=" relative bg-[#190b4c] text-white rounded-lg shadow-lg p-4">
            {/* Header Row with Icons */}
            <div className="flex justify-between items-center">
                <div>
                    <span className="font-bold pr-4">Filter</span>
                    {/* Expand/Collapse Button */}
                    <button onClick={toggleExpanded}>
                        {isExpanded ? <IoChevronUp /> : <IoChevronDown />}
                    </button>
                </div>

                <div className="flex items-center gap-3">
                    {/* Help Icon */}
                    <div className="relative pr-2">
                        <FaQuestionCircle
                            className="cursor-pointer hover:text-yellow-400"
                            onClick={() => setShowHelp(!showHelp)}
                            onMouseEnter={() => setShowHelp(true)}
                            onMouseLeave={() => setShowHelp(false)}
                        />
                        {showHelp && (
                            <div className="absolute right-0 top-full mt-2 bg-[#2e166c] text-white text-xs rounded p-2 shadow-lg z-10 w-56">
                                Select a pairing and status, and choose any number of tags to filter the novels. Trashcan button clears all filters.
                            </div>
                        )}
                    </div>

                    {/* Reset Button */}
                    <FaTrash
                        className="cursor-pointer hover:text-yellow-400"
                        onClick={resetFilters}
                    />
                </div>
            </div>

            {/* Collapsible Filters Content */}
            {isExpanded && (
                <div className="flex flex-row justify-center mt-4 space-x-4  ">

                    {/* Pairing Filter */}
                    <div>
                        <div className="text-sm text-center pb-2">Pairing</div>
                        <div className="flex flex-col gap-2">
                            {pairingOptions.map(option => (
                                <button
                                    key={option}
                                    className={`px-3 py-1 rounded ${filters.pairing === option ? 'bg-yellow-500 text-black' : 'bg-blue-800'}`}
                                    onClick={() => handlePairingSelect(option)}
                                >
                                    {option}
                                </button>
                            ))}
                        </div>
                    </div>
                    
                    {/* Status Filter */}
                    <div>
                        <div className="text-sm text-center pb-2">Status</div>
                        <div className="flex flex-col gap-2">
                            {statusOptions.map(option => (
                                <button
                                    key={option}
                                    className={`px-3 py-1 rounded ${filters.status === option ? 'bg-yellow-500 text-black' : 'bg-blue-800'}`}
                                    onClick={() => handleStatusSelect(option)}
                                >
                                    {option}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Tags Filter */}
                    <div>
                        <div className="text-sm text-center pb-2">Tags</div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 ">
                            {tagOptions.map(tag => (
                                <button
                                    key={tag}
                                    className={`overflow-hidden p-1 rounded ${filters.tags.includes(tag) ? 'bg-yellow-500 text-black' : 'bg-blue-800'}`}
                                    onClick={() => toggleTag(tag)}
                                >
                                    {tag}
                                </button>
                            ))}
                        </div>
                    </div> 
                </div>
            )}
        </div>
    );
};

export default FilterPanel;
