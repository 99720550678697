import React from "react";
import { RiSparkling2Fill } from 'react-icons/ri';
import { Link } from "react-router-dom";

const FloatingSparkles = () => {
    return (
        <>
            <RiSparkling2Fill className="absolute text-yellow-400 text-6xl top-[50px] animate-float pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-2xl top-[200px] left-16 animate-float-reverse pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-4xl top-32 right-10 animate-float-reverse pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-4xl bottom-20 animate-float-reverse pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-3xl bottom-28 right-1 animate-float pointer-events-none" />
            <RiSparkling2Fill className="absolute text-yellow-400 text-6xl bottom-5 right-20 animate-float pointer-events-none" />
        </>
    );
};

const TermsAndConditionsPage = () => {
    return (
        <div className="bg-gradient-to-b pt-20 from-blue-900 to-slate-950 min-h-screen text-white">
            <div className="max-w-5xl mx-auto px-6 relative">
                <FloatingSparkles />
                <header className="text-center relative py-10">
                    <h1 className="mt-10 font-amatica text-5xl font-semibold">Terms and Conditions</h1>
                </header>

                <div className="max-w-3xl mx-auto text-slate-200   space-y-3 pb-36">
                    <div className="max-w-4xl mx-auto text-slate-200 p-6 space-y-6 ">

                        
                        <p>Welcome to <span className="font-bold">Starcafe</span>, a fan translation website where I share fan translations of Asian novels. These translations are provided for free, but extra updates may be posted when I receive donations. Additionally, readers can access advance chapters by subscribing to my Ko-fi membership, which allows them to read a certain number of chapters ahead of the public release schedule.</p>
                        <p>By using this website, you agree to these Terms and Conditions and any updates I may apply in the future. If you do not agree with these terms, please refrain from using this website.</p>

                        <h2 className="text-2xl font-bold mt-6">1. Use of Content</h2>
                        <p>All translations on Starcafe are fan works and are not for commercial use. I do not own the original novels, but the fan translation itself is my work, and I reserve the rights to my translations.</p>
                        <ul className="list-disc list-inside space-y-2">
                            <li>Users are <span className="font-bold">NOT</span> allowed to copy, repost, or distribute any content from this website or my Ko-fi exclusive posts without explicit permission.</li>
                            <li>If someone steals my translations, I reserve the right to take action, which may include reporting the violation to relevant platforms or filing a DMCA takedown request against the infringing website.</li>
                        </ul>

                        <p className="p-1"></p>

                        <h2 className="text-2xl font-bold mt-6">2. Membership & Donations</h2>
                        <h3 className="text-xl font-semibold mt-4">Ko-fi Membership</h3>
                        <div className="pl-5">
                            <p className="pb-4">I offer three main membership tiers, which provide access to Ko-fi exclusive advance chapters:</p>
                            <ul className="list-disc list-inside space-y-2 pb-8">
                                <li><span className="font-bold">Little Star</span> ($3/month) → Access to 2 advance chapters</li>
                                <li><span className="font-bold">Super Nova</span> ($5/month) → Access to 4 advance chapters</li>
                                <li><span className="font-bold">Nebula Rebirth</span> ($10/month) → Access to 8 advance chapters</li>
                            </ul>
                            <p className=" pb-4">Additionally, there is a special tier called <span className="font-bold">Black Hole</span>, which is only available when I have fully translated a novel but still have at least 10 unpublished chapters on my website.</p>
                            <ul className="list-disc list-inside space-y-2">
                                <li>The Black Hole membership price is dynamic, starting at $10 plus $1 per remaining chapter.</li>
                                <li>Since the price changes as I release chapters, it is the responsibility of the supporter to unsubscribe when they no longer wish to continue.</li>
                                <li>I will disable this tier once the number of unpublished chapters drops below 10, but members should unsubscribe manually to avoid being charged again, as Ko-fi charges on a monthly cycle.</li>
                            </ul>
                        </div>
                        

                        <h3 className="text-xl font-semibold mt-4">Donations and Extra Updates</h3>
                        <div className="pl-5 space-y-3">
                            <p>Donations can lead to extra chapter updates, but the timing is based on my availability and stockpile.</p>
                            <p>My current rate is 1 extra chapter per $3 donation, and if my goal reaches $15, I will release an additional extra chapter for my ongoing translation projects.</p>
                            <p>However, I can only update a maximum of 3 chapters per day (including regular releases). If donations exceed this, extra chapters will be released in batches over multiple days.</p>
                            <p>I cannot guarantee daily releases of extra chapters due to other commitments, but I will do my best.</p>
                        </div>

                        <h3 className="text-xl font-semibold mt-4">Refunds</h3>
                        <div className="pl-5 space-y-3">
                            <p>Donations and membership fees are non-refundable, as they are considered voluntary contributions to support my translations and website.</p>
                            <p>However, if a refund request is made due to a very reasonable and understandable circumstance, I may issue a refund at my discretion.</p>
                            <p>Refunds will only be processed via PayPal and must be requested within 2 weeks of the original payment through Ko-fi messaging, contact me form, or email at <a href="mailto:starcafe.me@gmail.com" className="text-yellow-300">starcafe.me@gmail.com</a>.</p>
                            <p>Any processing fees deducted by PayPal will not be covered in the refund.</p>
                        </div>

                        <p className="pt-4"><span className="font-bold text-yellow-500">NOTE:</span> Membership perks and extra chapter rates from donations may change at my discretion.</p>

                        <p className="p-1"></p>

                        <h2 className="text-2xl font-bold mt-6">3. User Conduct</h2>
                        <p>To maintain a positive environment, users must adhere to the following rules:</p>
                        <ul className="list-disc list-inside space-y-2">
                            <li>Usernames must not contain offensive, discriminatory, or inappropriate language.</li>
                            <li>Comments must be respectful and should not contain hate speech, harassment, or inflammatory remarks.</li>
                            <li>Spam, harassment, or inappropriate content is strictly prohibited.</li>
                            <li>If a comment violates these rules, I reserve the right to delete it. If the violation is severe, I may remove the user’s account.</li>
                        </ul>

                        <p className="p-1"></p>

                        <h2 className="text-2xl font-bold mt-6">4. Limitation of Liability</h2>
                        <p>This website is designed, developed, and hosted entirely out of my pocket, with the support of donations from readers. As a result, delays, errors, or downtime may occur.</p>
                        <p>I am not responsible for any inconvenience caused by site issues, but I will do my best to resolve them as soon as possible.</p>
                        <p>If you encounter errors or issues, please contact me via email: <a href="mailto:starcafe.me@gmail.com" className="text-yellow-300">starcafe.me@gmail.com</a>.</p>

                        <p className="p-1"></p>

                        <h2 className="text-2xl font-bold mt-6">5. Changes to Terms</h2>
                        <p>These Terms and Conditions may be updated at any time.</p>
                        <p>Users will be notified of major changes, but it is their responsibility to review this page periodically to stay informed.</p>

                        <p className="p-1"></p>

                        <h2 className="text-2xl font-bold mt-6">6. Contact Information</h2>
                        <p>If you have any questions or concerns regarding these Terms, you can contact me through:</p>
                        <ul className="list-disc list-inside space-y-2">
                            <li>📧 <a href="mailto:starcafe.me@gmail.com" className="text-yellow-300">Email: starcafe.me@gmail.com</a></li>
                            <li>📩 Contact Form: On the Footer of the website.</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default TermsAndConditionsPage;
