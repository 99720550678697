import { FaCopyright} from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();

    return (
        <footer className="bg-slate-950 text-gray-500/50 p-4">
            <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between text-center md:text-left">

                {/* Left - Copyright & StarCafe */}
                <div className="flex items-center space-x-2  hover:text-yellow-500/50">
                    <FaCopyright />
                    <Link
                        to={`/`}
                    >
                        2025 StarCafe
                    </Link>
                </div>

                {/* Center - Social Media Links */}
                <div className="flex items-center justify-center text-center space-x-6 my-4">
                    <div
                        onClick={() => navigate("/about/#contactMe")}
                        className="hover:text-blue-500/50 transition min-w-1/3"
                    >
                        Contact Me
                    </div>
                    <div
                        onClick={() => navigate("/terms-and-conditions")}
                        className="hover:text-green-400/50 transition min-w-1/3"
                    >
                        Terms and Conditions
                    </div>
                    <div
                        onClick={() => navigate("/privacy-policy")}
                        className="hover:text-pink-500/50 transition min-w-1/3"
                    >
                        Privacy Policy
                    </div>
                </div>




                {/* Right - Developer Credits */}
                <a href="/" target="_blank" rel="noopener noreferrer" className="hover:text-yellow-100/50 transition">
                    Website by TBA
                </a>
            </div>
        </footer>
    );
};

export default Footer;
